/* Gallery Css Start Here */
.gallery_sec {
	padding: 60px 0;
}
.gallery_sec .w-60 img {
	width: 100%;
	height: 400px;
	object-fit: cover;
}
.gallery_sec .w-40 img {
	height: 180px;
	width: 100%;
	object-fit: cover;
}
.gallery_sec .geallery-img.w-30 img {
	height: 250px;
	object-fit: cover;
	width: 100%;
}
.gallery_sec .geallery-img {
	position: relative;
	overflow: hidden;
}
.gallery_sec .geallery-img:before {
	content: "";
	background: #c23127ab;
	width: 100%;
	height: 100%;
	position: absolute;
	bottom: 0;
	border-radius: 50% 0 50% 0;
	transition: 0.9s;
	transform: scale(0);
}
.gallery_sec .geallery-img:hover:before {
	transform: scale(1);
}
.gallery_sec .geallery-img figure {
    margin-bottom: 0;
}
/* Gallery Css End Here */