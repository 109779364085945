/* Video Css Start Here */
.videos_sec video {
    width: 100%;
    height: auto;
  }
  .videos_sec .video-container {
    position: relative;
    overflow: hidden;
  }
  .videos_sec .video-container .play-button-wrapper {
    position: absolute;
    bottom: 0;
    height: 100%;
    justify-content: center;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }
  .videos_sec .video-container .play-button-wrapper img {
    cursor: pointer;
  }
  .videos_sec {
    padding: 60px 0 40px;
  }
  .videos_sec .video-wrapper {
    margin-bottom: 20px;
  }
  /* Video Css End Here */