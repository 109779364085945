/* FAQ Sec Css Start Here */
.faqs_sec .before {
	position: absolute;
	left: 0;
	z-index: -1;
	top: 10%;
	filter: grayscale(1);
}
.faqs_sec {
	padding: 60px 0;
	position: relative;
}
.faqs_sec .heading {
	text-align: center;
	padding-bottom: 20px;
}
.faqs_sec .heading h2 {
	padding-bottom: 10px;
}
.faqs_sec .heading p {
	color: #000000ab;
}
.faqs_sec .accordion-item {
	border: 1px solid #7070708a;
	margin-bottom: 25px;
	border-radius: 10px;
}
.faqs_sec .accordion-item .accordion-header button {
	background: transparent !important;
	padding: 0 20px;
	height: 50px;
	font-size: 16px;
	color: #54544e;
	font-family: "SF UI Display";
	box-shadow: none;
}
.faqs_sec .accordion-item .accordion-header {
	border: 0 !important;
}
.faqs_sec .accordion-item .accordion-body {
	padding-top: 0;
	color: #000000a1;
}
.faqs_sec .accordion-item .accordion-header button[aria-expanded="true"] {
	color: #000;
}
.faqs_sec
	.accordion-item
	.accordion-header
	.accordion-button:not(.collapsed)::after {
	background-image: url("../images/minus.png");
	background-size: 16px;
}
.faqs_sec .accordion-item .accordion-header .accordion-button::after {
	background-image: url("../images/plus.png");
	background-size: 16px;
}
.faqs_sec .after {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: -1;
}
.accordion-button:not(.collapsed) {
    color: #000;
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
a {
    color: #c23127;
    text-decoration: none;
}
.accordion .btn-link:hover {
    color: #c23127;
}
.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: #f7f7f7;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
}
.accordion-button:not(.collapsed) {
    color: #000;
    background-color: #f7f7f7;
    box-shadow: none;
}
/* FAQ Sec Css End Here */

@media(max-width:768px){
	.faqs_sec .heading h2 {
		font-size: 32px;
		line-height: normal;
	}
	.card-body h2.contentheading {
		font-size: 32px;
		line-height: normal;
	}
	.card-header {
		padding: 0px;
	}
	.accordion button {
		font-size: 14px;
	}
	.accordion button:hover {
		font-size: 14px;
		font-weight: 500;
	}
  }
  @media(max-width:576px){

  }
