/* Donate Css Start Here */
.donate_now .before {
    position: absolute;
    bottom: 30%;
    z-index: -1;
    left: 0;
  }
  .donate_now {
    padding: 60px 20px;
    position: relative;
  }
  .donate_now .card-type {
    display: flex;
    max-width: 50%;
  }
  .donate_now .card-type input:checked ~ span.checkmark figure {
    box-shadow: 0px 0px 0px 1px #c23127;
  }
  .donate_now .card-type span.checkmark figure {
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin-right: 10px;
    width: 75px;
    overflow: hidden;
  }
  .donate_now form .heading {
    border-bottom: 1px solid #ddd;
    text-align: center;
    padding: 14px 0px;
  }
  .donate_now form {
    border: 1px solid #ddd;
    box-shadow: 0px 0px 4px #ddd;
    padding: 20px 30px;
    border-radius: 10px;
    background: #fff;
  }
  .donate_now form .heading h2 {
    font-size: 45px;
  }
  .donate_now form .heading {
    border-bottom: 1px solid #ddd;
    text-align: center;
  }
  .donate_now form .title h2 {
    color: #2d2525;
    font-size: 32px;
    padding: 13px 0px 5px;
    line-height: 42px;
  }
  .donate_now .donate_container span.checkmark h4 {
    width: 150px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 12px;
    padding: 3px 10px;
    border-radius: 50px;
  }
  .donate_now .donate_container span.checkmark .selected_amount {
    border-color: #c23127 !important;
  }
  /* .donate_now .donate_container input:checked ~ span.checkmark h4 {
    border-color: #c23127;
  } */
  .donate_now form .form-group {
    margin-top: 15px;
  }
  .donate_now form .form-group label {
    margin-bottom: 6px;
    font-size: 13px;
  }
  .donate_now form .form-group .form-control {
    border-radius: 50px;
    padding: 10px 30px;
  }
  .donate_now form .form-group .form-control::placeholder {
    font-size: 13px;
    color: #2a263d75;
  }
  .donate_now form .card-type {
    margin: 25px 0px;
  }
  .donate_now form .btn {
    background: #c23127;
    color: #fff;
    text-transform: uppercase;
    padding: 8px 15px;
    border-radius: 50px;
    margin-top: 10px;
  }
  .donate_now form .btn img {
    filter: brightness(0) invert(1);
    margin-left: 20px;
  }
  .donate_now form .form-group input.form-check-input {
    margin-right: 13px;
    border-radius: 0;
    border: 1px solid #636060;
  }
  .donate_now .after {
    position: absolute;
    bottom: 10%;
    right: 0;
    z-index: -1;
  }
  /* Donate Css End Here */

  @media(max-width:768px){
    .donation-form h2 {
      font-size: 36px;
  }
  }

  @media(max-width:576px){
    .donate_now .donate_container span.checkmark h4 {
      width: 250px;
      padding: 10px 10px;
  }

  }