/* Cart Css Start Here */
.cart_sec {
	padding: 60px 0;
}
.cart_sec table.table thead {
	background: #c23127;
	color: #fff;
	text-align: center;
}
.cart_sec table.table tbody tr td {
	padding: 0;
	text-align: center;
	padding: 1rem 8px;
	vertical-align: middle;
	border-bottom: 1px solid #00000070;
}
.cart_sec table.table tbody tr td select {
    background: transparent;
    border: 0;
	font-size: 12px;
}
.cart_sec table.table tbody tr td select:focus ,.cart_sec table.table tbody tr td select:visited{
	border: 0;
}
.cart_sec table.table tbody tr {
	/* border-right: 1px solid #00000070; */
}
.cart_sec table.table {
	vertical-align: middle;
}
.cart_sec table.table tbody tr td h4 {
	line-height: 21px;
	font-size: 15px;
	margin: 0;
}
.cart_sec table.table tbody tr .quantity input {
	width: 35%;
	margin: 0px auto;
	border-radius: 0;
	background: #f2f2f2;
	border: 0;
	text-align: center;
}
.cart_sec table.table tbody tr h4.price {
	color: #c23127;
}
.cart_sec table.table tbody tr h4.subtotal {
	color: #c23127;
}
.cart_sec table.table tbody tr td figure img {
	margin: 0px auto 0 0;
	display: table;
	width: 70px;
}
.cart_sec table.table tbody tr td.quantityTh {
	width: 17%;
}
.cart_sec table.table tbody tr td .delete {
	cursor: pointer;
}
.cart_sec .bottm-bar {
	background-color: #ececec;
	padding: 35px 40px 40px;
	border-radius: 0px 0px 20px 20px;
}
.cart_sec .bottm-bar .coupon {
	display: flex;
	align-items: center;
}
.cart_sec .bottm-bar .coupon label {
	margin-right: 15px;
	color: #525252;
}
.cart_sec .bottm-bar .coupon .form-group {
	display: flex;
	flex: 1;
	position: relative;
}
.cart_sec .bottm-bar .coupon .form-group .form-control {
	background: #fff;
	border: 0;
	font-size: 12px;
	color: #a8a8a8;
	padding: 16px 20px;
	border-radius: 50px;
}
.cart_sec .bottm-bar .coupon .form-group .form-control::placeholder {
	color: #a8a8a8;
}
.cart_sec .bottm-bar .coupon .form-group button.btn {
	background: #c23127;
	color: #fff;
	font-size: 17px;
	text-transform: uppercase;
	position: absolute;
	right: 7px;
	padding: 5px 25px;
	border-radius: 50px;
	top: 6px;
	transition: 0.6s;
}
.cart_sec .bottm-bar .coupon .form-group button.btn:hover {
	background: transparent;
	color: #c23127;
	box-shadow: 0px 0px 0px 1px #c23127;
}
.cart_sec .bottm-bar .button-group {
	text-align: center;
}
.cart_sec .bottm-bar .button-group .btn {
	transition: 0.6s;
	background: #343436;
	color: #fff;
	text-transform: uppercase;
	padding: 6px 20px;
	border-radius: 50px;
	width: 210px;
}
.cart_sec .bottm-bar .button-group .btn:hover {
	background: transparent;
	color: #343436;
	box-shadow: 0px 0px 0px 1px #343436;
}
.cart_sec .bottm-bar .button-group .btn:last-child {
	background: transparent;
	color: #343436;
	font-size: 17px;
}
.amount-calculation {
	padding-top: 20px;
}
.amount-calculation .all-amount {
	max-width: 400px;
}
.amount-calculation .all-amount p {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.amount-calculation .all-amount p span {
	color: #989898;
	font-weight: 400;
}
.amount-calculation .all-amount p.total {
	border-top: 1px solid #0000007a;
	padding-top: 13px;
}
.amount-calculation .all-amount p.total span {
	color: #ef5d61;
}
.amount-calculation .button-group {
	text-align: end;
}
.amount-calculation .button-group .btn {
	background: #c23127;
	color: #fff;
	text-transform: uppercase;
	padding: 6px 20px;
	border-radius: 50px;
	transition: 0.6s;
}
.amount-calculation .button-group .btn:hover {
	background: #0000;
	color: #c23127;
	box-shadow: 0px 0px 0px 1px #c23127;
}

.cart_sec .quantity{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cart_sec .quantity .minus{
	padding: 3px 12px;
    color: #fff;
    background-color: #c23127;
    border: unset;	
}
.cart_sec .quantity .plus{
	padding: 3px 10px;
    color: #fff;
    background-color: #c23127;
    border: unset;	
}
.cart_sec .quantity span{
	padding: 0px 12px;
}
/* Cart Css End Here */