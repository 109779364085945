.frequent-modal-main .modal-header{
	display: block;
}
.frequent-modal-main .close{
	position: absolute;
    right: 0;
    margin: 0px 8px;
    top: 9px;
}
.frequent-modal-main .modal-content {
    padding: 18px 8px !important;
}
.frequent-modal-main button{
	font-size: 12px;
    background: #212529;
    color: #fff;
	width: 100%;
}
.frequent-modal-main .modal-header h5{
	font-size: 18px;
    text-align: center;
    font-weight: bold;
}
.frequent-modal-main .modal-header h2{
	font-size: 24px;
    text-align: center;
    font-weight: bold;
}
.products-freq-modal{
	padding: 35px 0px;
}
.products-freq-modal .pro-img figure{
	height: 100px;
	width: auto;
	overflow: hidden;
}
.products-freq-modal .pro-img figure img{
	height: 100px;
	width: 100%;
}
.products-freq-modal .name h4{
	font-size: 13px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;  
	overflow: hidden;
}
.products-freq-modal .price h4{
	font-size: 13px;
}
.addon-data p{
	margin: 0;
}
.addonContainer{
	display: flex;
	/* justify-content: center; */
	align-items: center;
	/* margin: 25px 12px; */
}
.addon-img{
	height: 100px;
	width: 100px;
	overflow: hidden;
	margin: 0px 6px;
}
.addon-img img{
	height: 100px;
	width: 100px;
}
.Addresss-modal-btn{
	background-color: #c23127 !important;
	color: #fff !important;
}
.addon-details{
	display: flex;
	align-items: center;
}
.addon-details .addon-name{
	/* width: 50%; */
    font-size: 14px;
    color: #0000008a;
    font-weight: 600;
}
.addon-details .addon-price{
    font-size: 14px;
    color: #0000008a;
    font-weight: 600;
}
.addon-details .addon-img{
	height: 100px;
    width: 100px;
    overflow: hidden;
}
.addon-img img{
	height: 100px;
    width: 100px;
}
.donor-list-name{
	text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.editAddress i:hover{
	color: #c23127;
}
.deleteAddress i:hover{
	color: #c23127;
}
.about-image figure{
	width: 100%;
}
.about-image figure img{
	width: 100%;
}
.border-right{
    border-right: 2px solid #cfcfcf;
}
.swal-title {
	margin: 0px;
	font-size: 16px;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.21);
	margin-bottom: 28px;
  }
  
/* .donation-title{
	font-size: 30px !important;
    text-align: center !important;
    width: 100%;
} */
.selected-freq{
	border: 3px solid #cc1010 !important;
}
.donor-box{
	display: flex;
    width: 100%;
	margin-bottom: 20px;
}
.donor-box h4{
	font-size: 18px;
    margin-bottom: 0px;
}
.donor-box span{
	font-size: 12px;
}
.donor-list .img-box figure{
    height: 50px;
    width: 50px;
    overflow: hidden;
    border-radius: 50%;
}
.donor-list .img-box figure img{
    height: 50px;
    width: 50px;
}
.zone-data{
    color: #000000;
    font-weight: 600;
    font-size: 14px;
	text-align: center;
}
.page-notFound{
	text-align: center;
    padding: 90px 85px;
}
.page-notFound .icon{
	font-size: 70px;
	margin: 28px;
}
.donation-form{
	border: 1px solid #ddd;
    box-shadow: 0px 0px 4px #ddd;
    padding: 20px 30px;
    border-radius: 10px;
    background: #fff;
}
.donation-form .title h2{
		color: #2d2525;
		font-size: 32px !important;
		padding: 13px 0px 5px;
		line-height: 42px !important;
}
.accordion button{
	text-decoration: none;
	font-size: 16px;
	font-weight: 500;
}
.accordion button:hover{
	text-decoration: none;
	font-size: 16px;
	font-weight: 500;
	white-space: normal;
}
.userDetail{
	font-size: 19px;
}
.wishlist-add-btn{
	background: #c23127;
    border: 1px solid #fff;
    padding: 5px 8px;
}
.wishlist-add-icon{
	color: white;
}
.wishlist-add-icon:hover{
	color: rgb(25, 24, 24);
}
.upload-profile{
	padding: 0px 30px;
}
.upload-profile input{
	position: absolute;
    right: 0;
	opacity: 0;
	/* z-index: -1; */
}
.upload-profile .icon{
	font-size: 22px;
}
.upload-profile .icon:hover{
	color: #c23127;
}
.Addresss-modal-btn{
	background: #c23127;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    padding: 10px;
    text-transform: uppercase;
    width: 100%;
}
.modal-content {
	padding: 18px 40px;
	/* text-align: center; */
	width: 550px;
 }
.orderView-Modal{
	padding: 20px 0px;
 }
 
 .modal-mainTitle{
	font-size: 30px !important;
    text-align: center !important;
 }
 .orderView-Modal .modal-close{
	position: absolute;
	right: 0px;
	margin-right: 15px;
	font-size: 18px;
 }
 .orderView-Modal .modal-close :hover{
	color: red;
 }

 
.apply-filter-btn{
	transition: 0.6s;
    background: #343436;
    color: #fff;
    text-transform: uppercase;
    padding: 6px 20px;
    border-radius: 50px;
    width: 100%;
}
.apply-filter-btn:hover{
    background: #737383;
    color: #fff;
}
.pagination-container{
	text-align: center;
}
.page-item.active .page-link {
    z-index: 1 !important;
    color: #c23127 !important;
    background-color: #ffffff !important;
    border-color: #c23127 !important;
    border-radius: 4px !important;
}

.pagination-wrapper a.page-link {
	display: flex;
	line-height: 0px;
	height: unset;
	margin: 5px;
	padding: 14px;
	justify-content: center;
	font-family: "DM Sans";
	font-size: 18px;
	color: #6e6e6e;
	font-weight: 400 !important;
	font-style: normal !important;
	border: 0px;
}
.pagination li a i {
	line-height: 0px;
}

ul.pagination li.page-item:first-child a.page-link,
ul.pagination li.page-item:last-child a.page-link {
    border-radius: 5px !important;
    /* background: #296253; */
    background: #c23127;
    border-color: #c23127;
    color: #fff;
    border: 0px;
    font-size: 18px;
}
.Total-Product {
	/* align-items: center; */
	/* margin: 0 auto; */
	font-family: "Gotham";
	font-size: 12px;
	color: #666666;
	justify-content: center;
	display: flex;
}
.pagination-wrapper .active a.page-link {
	color: #fff;
	background: #a6674a;
	border: 1px solid #a6674a;
}

.page-item {
    padding: 4px;
}