/* Checkout Css Start Here */
.checkout {
	padding: 80px 0;
	position: relative;
}
.checkout .head {
	text-align: center;
	padding-bottom: 60px;
}
.checkout .head h1 {
	color: #c23127;
}
.checkout h3 {
	font-weight: 700;
	font-size: 20px;
}
/* First Column Css Start Here */
.checkout .first-top-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 40px;
}
.checkout .first-top-bar h3 {
	margin-bottom: 0;
}
.checkout .first-top-bar p {
	font-size: 15px;
	color: #000;
	font-weight: 500;
	margin: 0;
}
.checkout .first-top-bar p a {
	text-decoration: none;
	transition: 0.6s;
}
.checkout .first-top-bar p a:hover {
	color: #c23127;
}
.checkout .first-top-bar p a {
	font-weight: 500;
	color: #c23127;
	text-decoration: underline;
}
.checkout label {
	font-size: 15px;
	font-weight: 500;
	color: #2a263d;
}
.checkout select {
	border-radius: 0;
	height: 50px !important;
	border-color: #70707059;
	font-weight: 500;
	font-size: 14px;
}
/* Saved Address Css Start Here */
.checkout .saved-address label.container .select-address .selection {
    position: absolute;
    right: 5px;
    top: 0px;
    opacity: 0;
}
.checkout
	.saved-address
	label.container
	.select-address
	.selection
	i.fa.fa-check {
	background: #0eb702;
	color: #fff;
	font-size: 14px;
	width: 25px;
	height: 25px;
	text-align: center;
	line-height: 25px;
	border-radius: 50%;
}
.checkout
	.saved-address
	label.container
	input:checked
	~ span.checkmark
	.select-address
	.selection {
	opacity: 1;
}
.checkout .saved-address .title {
	padding-bottom: 25px;
}
.checkout .saved-address .title h5 {
	font-size: 15px;
}
/* The container */
.checkout .saved-address .container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
/* Hide the browser's default radio button */
.checkout .saved-address .container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.checkout .saved-address span.checkmark {
	position: unset;
}
.checkout .saved-address label.container {
	padding: 0;
}
.checkout .saved-address label.container .select-address {
	border: 2px solid #b5b5b5;
	padding: 20px 30px;
	position: relative;
	overflow: hidden;
}
.checkout
	.saved-address
	label.container
	input:checked
	~ span.checkmark
	.select-address {
	box-shadow: 0px 0px 0px 3px #c23127;
	border-color: #fff;
	border-radius: 10px;
}
.checkout .saved-address .select-address .radio-btn span {
	width: 15px;
	height: 15px;
	background: #fff;
	position: absolute;
	left: 0;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0px 0px 0px 1px #c23127;
	top: 10px;
	margin: 20px 20px;
}
.checkout
	.saved-address
	label.container
	input:checked
	~ span.checkmark
	.select-address
	.radio-btn
	span {
	background: #c23127;
}
.checkout .saved-address .select-address {
	position: relative;
}
.checkout .saved-address .select-address .address-detail {
	padding-left: 20px;
	margin-left: 30px;
	border-left: 1px solid #7070705c;
}
.checkout .saved-address .select-address .address-detail .top {
	display: flex;
}
.checkout .saved-address .select-address .address-detail h5 {
	width: 100%;
	padding: 4px 0;
}
.checkout .saved-address .select-address .address-detail h5 span.property {
	font-size: 16px;
	color: #00000096;
	font-weight: 600;
}
.checkout .saved-address .select-address .address-detail h5 span.value {
	margin-left: 20px;
	font-weight: 600;
	color: #000000;
	font-size: 16px;
}
.checkout .saved-address .select-address .address-detail h5.last span.value {
	margin-left: 33px;
}
.checkout .saved-address .select-address .button-group {
	position: absolute;
	bottom: 20px;
	right: 20px;
}
.checkout .saved-address .select-address .address-detail button.btn {
    background: #c23127;
    color: #ffffff;
	font-size: 15px;
	font-weight: 500;
	text-transform: capitalize;
	opacity: 1;
	border-radius: 0;
	padding: 6px 20px;
}
.checkout .saved-address .select-address .address-detail button.btn2 {
    background: #b9a4a3;
    color: #ffffff;
	font-size: 15px;
	font-weight: 500;
	text-transform: capitalize;
	opacity: 1;
	border-radius: 0;
	padding: 6px 20px;
	border: unset;
}
.checkout .saved-address .select-address .address-detail button.lnk {
	background: transparent;
	border: 0;
	font-size: 15px;
	color: #1473e6;
	text-decoration: underline;
	margin-left: 10px;
}
.checkout .add-new-address {
	padding-top: 20px;
	position: relative;
}
.checkout .form-group {
	margin-bottom: 15px;
}
.checkout .add-new-address label.container {
    padding: 0px;
    font-size: 15px;
    color: #262626;
    font-weight: 700;
}
.checkout .add-new-address label.container:hover{
	color: #971919;
	text-decoration: underline;
}
.checkout .add-new-address label.container span.checkmark {
	background: #fff;
	width: 15px;
	height: 15px;
	position: absolute;
	left: 0;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0px 0px 0px 1px #f35d63;
	top: 24.5px;
}
.checkout .add-new-address label.container input[type="radio"] {
    position: absolute;
    left: 0px;
    opacity: 0;
}
.checkout .add-new-address label.container input:checked ~ .custom_form {
    display: block;
}
.checkout .add-new-address label.container .custom_form {
    display: none;
}
.checkout .add-new-address label.container input:checked ~ span.checkmark {
	background: #c23127;
}
.checkout .add-new-address .form-group label {
	font-size: 15px;
	font-weight: 500;
	color: #2a263d;
	margin-bottom: 10px;
}
.checkout .add-new-address .form-group .form-control {
	border-radius: 0;
	height: 50px;
	border-color: #70707059;
	border-radius: 50px;
}
.checkout .add-new-address .checkbox {
	display: flex;
	align-items: center;
}
.checkout .add-new-address .checkbox .form-control {
	width: auto;
}
.checkout .add-new-address .checkbox label {
	margin-bottom: 0;
	color: #636060;
	font-size: 15px;
	padding-left: 15px;
}
/* Saved Address Css End Here */

/* Saved Payment Css Start Here */
.checkout .payment-details {
	padding-top: 30px;
}
.checkout .payment-details .heading {
	/* padding-bottom: 40px; */
}

.checkout .payment-details .saved-payment-method h5 {
	padding-bottom: 20px;
	font-size: 15px;
	color: #262626;
}
.checkout .payment-details .saved-payment-method label.container {
	padding: 0;
}
.checkout
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #70707099;
	padding: 10px 20px;
	position: relative;
	border-radius: 10px;
}
.checkout
	.payment-details
	.saved-payment-method
	label.container
	input:checked
	~ span.checkmark
	.card-details {
	box-shadow: 0px 0px 0px 2px #c23127;
	border-color: #fff;
}
.checkout
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.visa-card {
	display: flex;
	align-items: center;
	padding-left: 40px;
}
.checkout
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.visa-card
	figure {
	margin-bottom: 0;
}
.checkout
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.visa-card
	.input-box
	label {
	color: #000000;
	font-size: 8px;
	font-weight: 600;
}
.checkout
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.visa-card
	input {
	border: 0;
	height: unset;
	font-size: 18px;
	color: #343a40;
	font-weight: 600;
}
.checkout
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.extra-btn {
	display: flex;
	align-items: center;
}
.checkout
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.extra-btn
	button.btn {
	margin-right: 20px;
	background: #e7e7e7;
	color: #7d7d7d;
	font-size: 15px;
	font-weight: 500;
	text-transform: capitalize;
	opacity: 1;
}
.checkout
	.payment-details
	.saved-payment-method
	label.container.second-card
	button.btn.default.disabled {
	visibility: hidden;
}
.checkout
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.extra-btn
	.selection {
	opacity: 0;
}
.checkout
	.payment-details
	.saved-payment-method
	label.container
	input:checked
	~ span.checkmark
	.card-details
	.extra-btn
	.selection {
	opacity: 1;
}
.checkout
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.extra-btn
	.selection
	i {
	background: #0eb702;
	color: #fff;
	font-size: 14px;
	width: 25px;
	height: 25px;
	text-align: center;
	line-height: 25px;
	border-radius: 50%;
}
.checkout
	.payment-details
	.saved-payment-method
	label.container
	input[type="radio"] {
	opacity: 0;
}
.checkout
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.radio-btn
	span {
	background: #fff;
	width: 15px;
	height: 15px;
	position: absolute;
	left: 15px;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0px 0px 0px 1px #f35d63;
	top: 27.5px;
}
.checkout
	.payment-details
	.saved-payment-method
	label.container
	input:checked
	~ span.checkmark
	.card-details
	.radio-btn
	span {
	background: #c23127;
}
.checkout .add-new-address label.container span.checkmark {
	background: #fff;
	width: 15px;
	height: 15px;
	position: absolute;
	left: 0;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0px 0px 0px 1px #f35d63;
	top: 24.5px;
}
.checkout
	.payment-details
	.saved-payment-method
	.add-new-method
	label.container
	span.checkmark {
	border: 2px solid #fff;
	width: 15px;
	height: 15px;
	background: #fff;
	position: absolute;
	left: 0;
	border-radius: 50%;
	box-shadow: 0px 0px 0px 1px #f35d63;
	top: 33px;
}
.checkout .payment-details .saved-payment-method .add-new-method {
	position: relative;
}
.checkout
	.payment-details
	.saved-payment-method
	.add-new-method
	label.container {
	padding-left: 30px;
	font-size: 15px;
	color: #262626;
	font-weight: 700;
	padding-bottom: 15px;
}
.checkout .payment-details .saved-payment-method .add-new-method {
	padding-top: 30px;
}
.checkout
	.payment-details
	.saved-payment-method
	.add-new-method
	label.container
	input:checked
	~ span.checkmark {
	background: #c23127;
}
.checkout
	.payment-details
	.saved-payment-method
	.add-new-method
	.form-group
	.form-control {
	height: 50px;
	border-color: #70707059;
	border-radius: 50px;
	padding: 0 30px;
}
.checkout
	.payment-details
	.saved-payment-method
	.add-new-method
	.form-group
	.form-control::placeholder {
	color: #55555578;
	font-weight: 400;
	font-size: 15px;
}
.checkout .payment-details .saved-payment-method .add-new-method button {
	width: 100%;
	padding: 15px 0;
	cursor: pointer;
	background: #c23127;
	color: #fff;
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 600;
	border: 2px solid #fff;
	transition: 0.6s;
}
.checkout .payment-details .saved-payment-method .add-new-method button:hover {
	box-shadow: 0px 0px 0px 2px #c23127;
}
/* Saved Payment Css Start Here */

/* First Column Css End Here */

/* Second Column Css Start Here */
.checkout .second-top-bar .form-group {
	display: flex;
	align-items: flex-start;
}
.checkout .second-top-bar .form-group .form-control {
	border-radius: 0;
	border: 1px solid #b4b4b4;
	padding: 25px 20px;
}
.checkout .second-top-bar .form-group input.form-control::placeholder {
	font-family: "Poppins";
	color: #2a263d;
}
.checkout .second-top-bar .form-group input.form-control {
	padding: 15px 30px;
	border-radius: 50px;
}
.checkout .second-top-bar .form-group .form-control::placeholder {
	color: #2a263d;
	font-family: "Montserrat";
	font-weight: 500;
}
.checkout .second-top-bar .form-group button {
	height: 56.6px;
	width: 40%;
	margin-left: 15px;
	background: #c23127;
	color: #ffffff;
	font-size: 18px;
	font-weight: 500;
	cursor: pointer;
	border: 2px solid #fff;
	transition: 0.6s;
	border-radius: 50px;
}
.checkout .second-top-bar .form-group button:hover {
	box-shadow: 0px 0px 0px 2px #c23127;
}
.checkout .cart-product-detail {
	border: 1px solid #b4b4b4;
	padding: 50px 50px;
	position: relative;
	overflow: hidden;
	border-radius: 20px;
}
.checkout .cart-product-detail .checkout-products {
    height: auto;
    overflow-y: scroll;
    overflow-x: hidden;
}
.checkout .cart-product-detail .checkout-products::-webkit-scrollbar {
	width: 0.2em;
}
.checkout .cart-product-detail .checkout-products::-webkit-scrollbar-thumb {
	background-color: #c23127;
}
.checkout .cart-product-detail .products {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #70707073;
	padding: 12px 0;
	position: relative;
	margin-right: 15px;
}
.checkout .cart-product-detail .br-right:before {
	content: "";
	background: #c23127;
	width: 4px;
	height: 60%;
	position: absolute;
	display: flex;
	align-items: center;
	right: -20px;
}
.checkout .cart-product-detail .products .abt-product {
	display: flex;
	align-items: center;
}
.checkout .cart-product-detail .products .abt-product .img-box img {
	width: 60px;
}
.checkout .cart-product-detail .products .abt-product .pro-dtl {
	padding-left: 15px;
}
.checkout .cart-product-detail .products .abt-product .pro-dtl h5.name {
	font-size: 13px;
	font-weight: 500;
	color: #000;
	margin: 0;
}
.checkout .cart-product-detail .products .abt-product .pro-dtl ul.variations {
	margin-bottom: 0;
	padding: 0;
	list-style: none;
	/* display: flex; */
	/* align-items: center; */
}
.checkout .cart-product-detail .products .img-box figure {
	margin-bottom: 0;
}
.checkout
	.cart-product-detail
	.products
	.abt-product
	.pro-dtl
	ul.variations
	li {
	/* padding: 0 7px; */
	font-weight: 500;
	color: #1f2c35;
	font-size: 13px;
}
.checkout
	.cart-product-detail
	.products
	.abt-product
	.pro-dtl
	ul.variations
	li:last-child {
	border: 0;
	/* width: 250px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.checkout
	.cart-product-detail
	.products
	.abt-product
	.pro-dtl
	ul.variations
	li:first-child {
	padding-left: 0;
}
.checkout .cart-product-detail .products .product-price h4.price {
	color: #c23127;
	font-size: 17px;
	font-weight: 600;
}
.checkout .cart-product-detail .pricing-details {
	padding-top: 40px;
}
.checkout .cart-product-detail .pricing-details .item-total h4.property {
	font-size: 17px;
	color: #000000;
	font-weight: 600;
}
.checkout .cart-product-detail .pricing-details .item-total h4.value {
	font-size: 17px;
	color: #000000;
	font-weight: 600;
}
.checkout .cart-product-detail .pricing-details .item-total {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;
}
.checkout .cart-product-detail .pricing-details {
	border-bottom: 1px solid #c5c5c5;
	padding-bottom: 30px;
}
.checkout .cart-product-detail .order-total {
	padding-top: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.checkout .cart-product-detail .order-total h3.property {
	color: #c23127;
}
.checkout .cart-product-detail .order-total h3.value {
	font-weight: 600;
}
/* Second Column Css End Here */
.checkout .border-last {
	background: #7070705c;
	padding: 0.5px;
	margin: 40px 0;
}
.checkout .confirm-orderDv {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.checkout .confirm-orderDv .checkbox {
	display: flex;
	align-items: center;
}
.checkout .confirm-orderDv .checkbox label {
	margin-bottom: 0;
	padding-left: 10px;
	font-size: 17px;
	color: #000000;
	font-weight: 600;
	margin-top: 5px;
}
.checkout .confirm-orderDv .button-group button {
	width: 100%;
	padding: 9px 50px;
	cursor: pointer;
	background: #c23127;
	color: #fff;
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 600;
	border-radius: 50px;
	transition: 0.6s;
	border: 2px solid #fff;
}
.checkout .confirm-orderDv .button-group button:hover {
	box-shadow: 0px 0px 0px 2px #c23127;
}
.checkout .confirm-orderDv .checkbox .form-control {
	width: unset;
}
.checkout .before {
	position: absolute;
	left: 0;
	top: 25%;
	z-index: -1;
	filter: grayscale(1);
}
.checkout .after {
	position: absolute;
	bottom: 20%;
	z-index: -1;
	right: 0;
}
.checkout .payment-details .card-type {
	display: flex;
	max-width: 50%;
}
.checkout .payment-details .card-type input:checked ~ span.checkmark figure {
	box-shadow: 0px 0px 0px 1px #c23127;
}
.checkout .payment-details .card-type span.checkmark figure {
	height: 35px;
	line-height: 35px;
	text-align: center;
	margin-right: 10px;
	width: 75px;
	overflow: hidden;
}
/* Checkout Css Start Here */
/* Media Query Start Here */
@media (max-width: 768px) {
	.checkout .confirm-orderDv .button-group button {
		width: 220px;
		padding: 12px 0px;
		font-size: 12px;
	}
	.checkout .payment-details .saved-payment-method .add-new-method {
		margin-bottom: 40px;
	}
}
@media (max-width: 575px) {
	.checkout .saved-address label.container .radio-btn {
		display: none;
	}
	.checkout .saved-address .select-address .address-detail {
		padding-left: 0px;
		margin-left: 0px;
		border-left: 0;
	}
	.checkout .saved-address .select-address .address-detail button.btn {
		position: unset;
	}
	.checkout .confirm-orderDv {
		display: block;
	}
	.checkout .confirm-orderDv .checkbox {
		margin-bottom: 20px;
	}
	.checkout .cart-product-detail {
		padding: 50px 20px;
	}
	span.property.pl-4 {
		padding-left: 0px !important;
	}
	.checkout .saved-address .select-address .address-detail .top {
		display: block;
	}
	.checkout .saved-address .select-address .button-group {
		position: unset;
	}
	.checkout .second-top-bar .form-group input.form-control {
		padding: 17px 20px;
		border-radius: 50px;
		font-size: 0.75rem;
	}
	.checkout .second-top-bar .form-group button {
		font-size: 14px;
	}
}
@media (max-width: 500px) {
	.checkout .first-top-bar {
		display: block;
		text-align: center;
	}
	.checkout .saved-address .select-address .address-detail h5 span.value {
		font-size: 11px;
	}
	.checkout .saved-address .select-address .address-detail h5 span.property {
		font-size: 12px;
		font-weight: 500;
	}
	.checkout .saved-address label.container .select-address {
		padding: 20px 10px;
	}
}
@media (max-width: 400px) {
	.checkout
		.payment-details
		.saved-payment-method
		label.container
		span.checkmark
		.card-details
		.extra-btn
		button.btn {
		display: none;
	}
}