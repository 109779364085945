/* Campaign Css Start Here */
.donate-btn{
  background: #c23127;
  color: #fff;
  text-transform: uppercase;
  border-radius: 50px;
  padding: 6px 30px;
}
.campaign {
    padding: 60px 0px;
    position: relative;
  }
  .campaign figure{
    width: 100%;
    height: 400px;
    overflow: hidden;
  }
  .campaign figure img{
    height: 400px;
    width: 100%;
  }
  .campaign .before {
    position: absolute;
    left: 0;
    z-index: -1;
    top: 35%;
  }
  .campaign p {
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
  }
  .campaign .progress {
    height: 30px;
    border-radius: 50px;
  }
  .campaign .progress .progress-bar {
    background: #c23127;
    font-size: 17px;
  }
  .campaign .progress span.goal {
    line-height: 30px;
    margin-left: 30px;
    font-size: 15px;
  }
  .campaign .content-wrapper .chart {
    position: relative;
  }
  .campaign .content-wrapper .chart .goal {
    position: absolute;
    left: 125px;
    top: 75px;
    text-align: center;
  }
  .campaign .content-wrapper .chart h5 {
    margin: 0;
    color: #c23127;
    font-size: 10px;
    line-height: 10px;
  }
  .campaign .content-wrapper .chart h4 {
    font-size: 12px;
    color: #c23127;
    font-weight: 700;
  }
  .campaign .content-wrapper .chart .donate {
    text-align: center;
    position: absolute;
    bottom: 50px;
    right: 32%;
  }
  .campaign .content-wrapper .chart .donate h4 {
    color: #fff;
  }
  .campaign .content-wrapper .chart .donate h5 {
    color: #fff;
  }
  .campaign .for-chart {
    display: flex;
    align-items: center;
  }
  .campaign .for-chart .left {
    width: 70%;
    margin-left: -50px;
  }
  .campaign .for-chart .right {
    flex: 1;
    margin-top: 15px;
  }
  .campaign .for-chart button.btn.btn-primary {
    background: transparent;
    border: 0;
    text-align: left;
    padding: 0;
    box-shadow: none;
    outline: none;
    cursor: pointer;
  }
  .campaign .box {
    border-bottom: 1px solid #7070706b;
    max-width: 110px;
    padding: 5px 0px;
  }
  .campaign .box h4 {
    margin: 0;
    font-size: 17px;
    color: #000000;
  }
  .campaign .box h5 {
    font-size: 14px;
    color: #00000078;
    margin: 0;
  }
  .campaign .button-group {
    margin-top: 20px;
  }
  .campaign .button-group a.btn {
    background: #c23127;
    color: #fff;
    border-radius: 50px;
    text-transform: uppercase;
    padding: 9px 30px;
  }
  .campaign .button-group a.btn img {
    filter: brightness(0) invert(1);
    margin-left: 20px;
  }
  .campaign .scnd_row h2 {
    line-height: 48px;
    padding-bottom: 10px;
  }
  #donors .modal-content {
    border: 0;
    border-radius: 10px;
    padding: 0px 30px;
  }
  #donors .modal-header {
    padding: 20px 0px;
    justify-content: center;
  }
  #donors .modal-header h5 {
    margin: 0;
    /* font-family: "oriental"; */
    font-size: 45px;
    color: #000;
    font-family: "Montserrat";
  }
  #donors .donor-list .donor-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-right: 15px;
  }
  #donors .donor-list .donor-box .content .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  #donors .donor-list .donor-box .content .header p {
    margin: 0;
  }
  #donors .modal-body {
    padding: 30px 0;
  }
  #donors  .donor-list .donor-box .img-box img {
    max-width: 75px;
    border: 3px solid #c23127;
    border-radius: 50%;
    margin-right: 15px;
  }
  #donors .donor-list .donor-box .content .header h4 {
    color: #000000;
    font-size: 16px;
    margin: 0;
    font-weight: 600;
  }
  #donors .donor-list .donor-box .content .header .amount p {
    background: #efefef;
    padding: 2px 10px;
    border-radius: 5px;
  }
  #donors
    .donor-list
    .donor-box
    .content
    .header
    .amount
    p
    span.property {
    font-size: 13px;
    font-weight: 700;
    color: #000000;
  }
  #donors
    .donor-list
    .donor-box
    .content
    .header
    .amount
    p
    span.value {
    font-size: 11px;
    margin-left: 10px;
    text-decoration: underline;
  }
  #donors  .donor-list .donor-box p {
    font-size: 13px;
    font-weight: 300;
    line-height: 18px;
  }
  #donors .donor-list {
    height: 400px;
    overflow: auto;
  }
  #donors .donor-list::-webkit-scrollbar {
    width: 0.2em;
  }
  #donors .donor-list::-webkit-scrollbar-thumb {
    background-color: #c23127;
  }
  .our_donor {
    padding: 20px 0px 60px;
  }

  .our_donor .heading {
    text-align: center;
    padding-bottom: 40px;
  }
  .our_donor .heading p {
    font-weight: 300;
    line-height: 22px;
    max-width: 80%;
    margin: 0px auto;
  }
  .our_donor .img-box {
    padding: 0px 30px;
    display: flex;
    justify-content: center;
  }
  .our_donor .button-group {
    text-align: center;
    margin-top: 40px;
  }
  .our_donor .button-group a.btn {
    background: #c23127;
    color: #fff;
    text-transform: uppercase;
    border-radius: 50px;
    padding: 6px 30px;
  }
  .campaign .after {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  .our_donor .donar-user-img{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
  }

  .our_donor .donar-user-img img{
    width: 200px;
    height: 200px;
  }

  /* Campaign Css End Here */

  @media (max-width: 1024px) {
    .campaign .content-wrapper h2 {
      font-size: 36px;
      line-height: normal;
  }
  .campaign .for-chart .left {
    width: 60%;
    margin-left: 0px;
}
.gUOCOV {
  gap: 0px !important;
}
.campaign .box {
  margin-left: 40px;
}
.campaign .button-group {
  margin-top: 0px;
}
.our_donor .img-box {
  padding: 0;
}
}

@media(max-width:576px){
  .campaign .for-chart {
    display: block;
}
.campaign .for-chart .left {
  width: 90%;
  margin-left: 0px;
}
.campaign .box {
  max-width: 100%;
  margin-left: 0px;
}
.campaign .button-group {
  margin-top: 20px;
}
.our_donor .heading h2 {
  font-size: 36px;
}
.our_donor .heading p {
  max-width: 100%;
}
}