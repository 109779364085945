/* Gift Css Start Here */
.gift_sec {
    padding: 40px 0;
  }
  .gift_sec .heading {
    text-align: center;
  }
  .gift_sec .heading h2 {
    color: #2d2525;
    font-size: 44px;
    line-height: 54px;
  }
  .gift_sec .heading p {
    color: #0000009c;
    padding: 0 10%;
  }
  .gift_sec form {
    border: 1px solid #b4b4b4;
    padding: 35px 60px;
    border-radius: 20px;
    margin-top: 15px;
  }
  .gift_sec form .form-group {
    margin-bottom: 20px;
  }
  .gift_sec form .form-group label {
    margin-left: 15px;
    margin-bottom: 5px;
  }
  .gift_sec form .form-group .form-control {
    border: 1px solid #70707091;
    padding: 14px 23px;
    border-radius: 50px;
    color: #2a263d87;
    height: auto;
  }
  .gift_sec form .form-group .form-control::placeholder {
    color: #2a263d87;
    font-weight: 400;
  }
  .gift_sec form .button-group button {
    width: 100%;
    background: #c23127;
    color: #fff;
    text-transform: uppercase;
    padding: 13px 0;
    border-radius: 50px;
  }
  button.stripePay-Btn img {
    display: none;
}
  /* Gift Css End Here */

  @media(max-width:768px){
    .gift_sec .heading h2 {
      font-size: 32px;
      line-height: normal;
  }
  .gift_sec .heading p {
    padding: 0%;
}
  }

  @media(max-width:576px){
    .gift_sec form {
      padding: 35px 30px;
    }
  }

  