/* Font Family */
@import url("https://fonts.cdnfonts.com/css/sf-ui-display");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
/* Universal Css Start Here */
:root {
	--first-color: #c23127;
	--second-color: #2d2525;
}
html {
	scroll-behavior: smooth;
	overflow-x:hidden !important;
}
body {
	font-family: "SF UI Display", sans-serif;
	overflow-x: hidden !important;
}
figure{
	margin: 0px !important;
}
@font-face {
	font-family: "oriental";
	font-display:swap;
	src: url("../fonts/oriental-bold.ttf");
}
@font-face {
	font-family: "oriental-light";
	font-display:swap;
	src: url("../fonts/oriental.ttf");
}
@font-face {
	font-family: "falmera";
	font-display:swap;
	src: url("../fonts/Falmera_Pearson.ttf");
}
p {
	color: #222a41;
	font-size: 16px;
	font-family: "SF UI Display", sans-serif;
}
h1 {
	/* font-family: "oriental"; */
	font-size: 111px;
	/* list-style: 121px; */
	font-family: "Montserrat";
}
h2 {
	/* font-family: "oriental"; */
	font-size: 56px;
	font-weight: 600;
	line-height: 66px;
	font-family: "Montserrat" !important;
}
h3 {
	font-size: 22px;
	font-weight: 400;
	line-height: 32px;
}
h4 {
	font-size: 20px;
	line-height: 30px;
	font-weight: 400;
}
h5 {
	font-size: 13px;
	line-height: 23px;
}
figure {
    margin-bottom: 0;
}


@media (max-width: 1024px) {
	h1 {
		font-size: 60px;
	}
	h2 {
		font-size: 46px;
	}
}

@media (max-width: 768px) {

}

@media (max-width: 576px) {

}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
a.kigi-btn {
	background-color: #c23127;
	padding: 10px 30px;
	color: #fff;
	text-decoration: none;
	transition: 0.6s;
	border-radius: 50px;
}
figure {
	margin-bottom: 0;
}
.form-check-input:checked[type="checkbox"] {
	border-color: #c23127 !important;
	background-color: transparent !important;
	background-image: url("../images/tick.png") !important;
	background-repeat: no-repeat;
	background-size: 10px;
	box-shadow: none;
}
/* Inner Banner Css Start Here */
.innerBanner .inner-box {
	background-image: url(../images/inner-banner.png);
	text-align: center;
	height: 165px;
	object-fit: cover;
	border-radius: 0px 0px 40px 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
}
.innerBanner .inner-box h2 {
	margin: 0;
	font-size: 40px !important;
	text-transform: uppercase;
	display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
@media(max-width:1366px){
	.innerBanner .inner-box h2{
		font-size: 60px;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}
@media(max-width:576px){
	.innerBanner .inner-box h2 {
		font-size: 26px !important;
		line-height: normal;
	}
}
/* Inner Banner Css End Here */

/* Thank You Screen Css Start Here */
.tahnk-you {
	padding: 60px 0px;
}
.tahnk-you .thank-box {
	background: #eee;
	padding: 40px 50px;
	text-align: center;
}
.tahnk-you .thank-box h3 {
	padding-bottom: 10px;
	/* font-family: "oriental"; */
	font-size: 50px;
	line-height: 60px;
	color: #c23127;
	font-family: "Montserrat";
}
.tahnk-you .thank-box p {
	font-size: 14px;
	color: #555;
	padding-bottom: 20px;
}
/* Thank You Screen End Here */

/* Search Bar Css Start Here */
.search-bar {
	position: absolute;
	z-index: 9;
	background: #c23127;
	width: 100%;
	padding: 25px 0;
	top: 0;
	transform: translateY(-180%);
	transition: 0.6s;
}
.search-bar.active {
	transform: translateY(0%);
}
.search-bar form .form-group {
	display: flex;
	position: relative;
}
.search-bar form .form-group .form-control {
	border-radius: 0;
	border: 0;
	padding: 10px 20px;
	box-shadow: none;
	outline: none;
}
.search-bar form .form-group button.search {
	position: absolute;
	right: 10px;
	top: 4px;
	border: 0;
	background: transparent;
	color: #c23127;
	font-size: 22px;
}
.search-bar .cancel {
	background: #fff;
	position: absolute;
	right: 10px;
	top: 10px;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	cursor: pointer;
}
/* Search Bar Css End Here */

/* Header Css Start Here */
header#headerTop {
    padding: 20px 0px;
    background: #fff;
    box-shadow: 0px 0px 10px #ddd;
    border-top: 15px solid #c23127;
    position: relative;
    z-index: 1;
}
header#headerTop ul.menus {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;
}
header#headerTop ul.menus li {
	display: inline-block;
}
header#headerTop ul.menus li .btn {
	background: transparent;
	border: 0;
	padding: 0;
	outline: none;
	font-size: 11px;
	margin: 0 4px;
	text-transform: uppercase;
	color: #000;
	font-weight: 300;
	text-decoration: none;
	margin-top: 3px;
	box-shadow: none;
}
header#headerTop ul.menus li .dropdown-menu {
	display: none !important;
}
header#headerTop ul.menus li .dropdown:hover .dropdown-menu {
	display: block !important;
}
header#headerTop ul.menus li ul.dropdown-menu {
	border-radius: 0;
	margin-top: -2px !important;
}
header#headerTop ul.menus li a {
	font-size: 13px;
	margin: 0 4px;
	text-transform: uppercase;
	color: #000;
	font-weight: 300;
	text-decoration: none;
	background: transparent;
	font-family: "SF UI Display", sans-serif;
}
header#headerTop ul li a.active {
	border-bottom: 3px solid #c4000a;
	padding-bottom: 8px;
}
header#headerTop .right-side ul {
	padding: 0;
	list-style: none;
	margin: 0;
}
header#headerTop .right-side ul li {
	display: inline-block;
	margin-right: 14px;
	position: relative;
	cursor: pointer;
}
header#headerTop .right-side {
	text-align: end;
}
header#headerTop .right-side ul li i {
	color: #c23127;
	font-weight: 600;
}
header#headerTop .right-side ul li a.kigi-btn {
	border-radius: 50px;
	padding: 12px 30px;
	transition: 0.6s;
}
header#headerTop .right-side ul li a.kigi-btn:hover {
	background: #0000;
	color: #c23127;
	box-shadow: 0px 0px 0px 2px #c23127;
}
header#headerTop .right-side ul li span.count {
	background: #c23127;
	color: #fff;
	font-size: 7px;
	font-weight: 300;
	width: 16px;
	position: absolute;
	text-align: center;
	height: 16px;
	border-radius: 50%;
	line-height: 16px;
	left: 14px;
	top: -4px;
}
header#headerTop .right-side ul li.canvas_btn {
	display: none;
}
header#headerTop .logo_box figure {
  margin-bottom: 0;
}
@media (max-width: 1100px) {
	header#headerTop .right-side ul li a.kigi-btn {
		padding: 8px 17px;
		font-size: 13px;
	}
	header#headerTop .right-side ul li {
		margin-right: 18px;
	}
}
@media (max-width: 992px) {
	header#headerTop .navbar_menus {
		display: none;
	}
	header#headerTop .right-side ul li.canvas_btn {
		display: inline-block;
	}
}
@media (max-width: 576px) {
	header#headerTop .right-side ul li.canvas_btn {
		margin: 0px;
	}
	header#headerTop .right-side ul li {
		margin-right: 15px;
	}
	
}
@media (max-width: 290px) {
	header#headerTop .col-lg-7.col-1 {
		display: none;
	}
	header#headerTop .right-side ul li a.kigi-btn {
		font-size: 13px;
		padding: 8px 7px;
	}
}
/* Header Css End Here */

/* Mobile Header Css Start Here */
header .canvas_btn {
	display: flex;
	justify-content: end;
	padding: 0 10px 0 0px;
}
.canvas_btn button.btn.btn-open {
	background: transparent;
	border-radius: 0;
	border: 0;
	width: 25px;
	padding: 0;
	display: none;
	flex-direction: column;
	box-shadow: 0 0 0 0;
	cursor: pointer;
}
.canvas_btn button.btn.btn-open span.menu-line {
	width: 35px;
	height: 2px;
	background: #fff;
	margin: 3px 0;
	display: block;
}
.canvas_btn button.btn.btn-open span.menu-line.line-2x {
	width: 25px;
}
.mobile_header {
	position: fixed;
	z-index: 2;
	right: 0;
	background: #c23127;
	width: 30%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0px 0px 0px 30px;
	transition: 0.8s;
	transform: translateX(120%);
	top: 0;
	z-index: 99999;
}
.mobile_header.show {
	transform: translateX(0%);
}
.mobile_header .cancel {
	position: absolute;
	top: 10px;
	right: 40px;
	background: #fff;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.mobile_header ul.mobile_menus {
	padding: 14px 0px;
	margin: 0;
	list-style: none;
}
.mobile_header ul.mobile_menus li.menu_items {
	margin: 10px 0;
}
.mobile_header ul.mobile_menus li.menu_items.menu_btn {
	margin-top: 30px;
}
.mobile_header ul.mobile_menus li.menu_items a {
	color: #fff;
	font-size: 15px;
	font-weight: 500;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
}
/* .mobile_header ul.mobile_menus li.menu_items.menu_btn a {
	background: #c98536;
	padding: 13px 25px;
	border-radius: 50px;
  } */
.mobile_header ul.mobile_menus li.menu_items.cart_btn a span.count {
	background: #c98536;
	width: 15px;
	height: 15px;
	line-height: 15px;
	font-size: 9px;
	text-align: center;
	border-radius: 50%;
	position: absolute;
	right: -10px;
}
@media (max-width: 991px) {
	header .canvas_btn button.btn.btn-open {
		display: flex;
	}
}
@media (max-width: 768px) {
	.mobile_header {
		width: 100%;
	}
	.table thead th {
		vertical-align: baseline;
	}
}
@media (max-width: 575px) {
	.mobile_header {
		width: 100%;
	}
	.mobile_header .cancel {
		top: 10px;
		right: 20px;
		width: 30px;
		height: 30px;
	}
	.table thead th {
		vertical-align: baseline;
	}
}
@media (max-width: 480px) {
	.mobile_header {
		width: 100%;
	}
}
/* Mobile Header Css Start Here */

/* Banner Css Start here */
.banner_sec .banner_content {
	position: relative;
}
.banner_sec .banner_content .video_box {
	position: relative;
	overflow: hidden;
}
.banner_sec .banner_content .video_box .toggle-btn {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}
.banner_sec .banner_content .video_box video#myvid {
	border-radius: 0px 0px 50px 50px;
}
.banner_sec .banner_content .content_dv {
	position: absolute;
	bottom: 0;
	text-align: center;
	left: 0;
	right: 0;
	padding: 60px 0;
}
.banner_sec .banner_content .content_dv h1 {
	color: #fff;
	text-transform: uppercase;
	font-size: 111px;
    font-family: "Montserrat";
}
.banner_sec .banner_content .content_dv .button-group {
	margin-top: 30px;
}
.banner_sec .banner_content .content_dv .button-group a.btn {
	background: #ffffff;
	padding: 10px 30px;
	align-items: center;
	color: #c23127;
	border-radius: 50px;
	position: relative;
	z-index: 3;
	transition: 0.6s;
}
.banner_sec .banner_content .content_dv .button-group a.btn:hover {
	background: #c23127;
	color: #fff;
}
.banner_sec .banner_content .content_dv .button-group a.btn img {
	transition: 0.6s;
}
.banner_sec .banner_content .content_dv .button-group a.btn:hover img {
	filter: brightness(0) invert(1);
}
@media (max-width: 1300px) {
	.banner_sec .banner_content .content_dv h1 {
		font-size: 80px;
	}
}
@media (max-width: 1024px) {
	.banner_sec .banner_content .content_dv h1 {
		font-size: 66px;
	}
}
@media (max-width: 576px) {
.banner_sec .banner_content .content_dv {
    padding: 30px 0;
}
.banner_sec .banner_content .content_dv .button-group {
    margin-top: 15px;
}
}
/* Banner Css End Here */

/* Quality Sec Start Here */
.quality_sec {
	background-color: #c23127;
	padding: 120px 0px 50px;
	margin-top: -80px;
}
.quality_sec p {
	margin: 0;
}
.quality_sec .icon_with_text .iconDv {
    display: flex;
    margin-bottom: 10px;
    align-items: baseline;
    min-height: 60px;
    gap: 10px;
}
.quality_sec .icon_with_text .iconDv figure {
    margin-right: 20px;
    margin-bottom: 0;
    height: auto;
    width: 60px;
    overflow: hidden;
} 
.quality_sec .icon_with_text .iconDv figure img {
    height: auto;
    width: 50px;
}
.quality_sec .icon_with_text .iconDv h4 {
	color: #ffffff;
	font-weight: 500;
	margin: 0;
}
.quality_sec .icon_with_text p {
	font-size: 17px;
	color: #ffffff8c;
	font-weight: 300;
	line-height: 25px;
	/* padding-right: 100px; */
}
/* Quality Sec End Here */

@media (max-width: 768px) {
	.icon_with_text {
		margin-bottom: 20px;
	}
}
@media (max-width: 576px) {
	.quality_sec .icon_with_text .iconDv {
		gap: 10px;
	}

}

/* Browse Product Css Start Here */
.prouct-sec {
	position: relative;
	padding: 80px 0px;
}
.prouct-sec .left {
	position: absolute;
	z-index: -1;
	left: 0;
	top: 0;
}
.prouct-sec .left img {
	filter: grayscale(1);
}
.prouct-sec .right {
	position: absolute;
	right: 0;
	top: 0;
	z-index: -1;
}
.prouct-sec .heading {
	text-align: center;
}
.discover {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 60px;
	padding-bottom: 25px;
}
.discover .heading_wrapper h2 {
    position: relative;
    font-size: 56px;
    font-weight: 600;
    line-height: 66px;
    font-family: "Montserrat";
}
.discover .heading_wrapper h2:before {
	content: "";
	background: #c23127;
	width: 60px;
	height: 60px;
	position: absolute;
	left: 0;
	border-radius: 50%;
	z-index: -1;
	top: 5px;
}
.discover p {
	color: #00000087;
	font-weight: 300;
}
.discover .view-all a.lnk {
	color: #c23127;
	font-size: 20px;
	text-transform: uppercase;
	text-decoration: none;
}
.discover .view-all a.lnk img {
	transition: 0.6s;
}
.discover .view-all a.lnk:hover img {
	transform: translateX(10px);
}
.prouct-sec .product-box {
	margin: 0 10px;
}
.product-box a {
	text-decoration: none;
	outline: none;
	box-shadow: none;
}
.product-box .pro-img {
	border: 1px solid #7070705c;
	background: #f4f4f4;
	display: flex;
	align-items: center;
	justify-content: center;
}
.shop_sec .product-box .pro-img {
	height: 275px;
}
.shop_sec .product-box .pro-img img {
	transition: 0.6s;
	margin: 0px auto;
	display: table;
}
.prouct-sec .product-box .pro-img {
	/* height: auto; */
	height: 376px;
    width: 100%;
    overflow: hidden;
}
.prouct-sec .product-box .pro-img img {
	height: 376px;
    width: 100%;
	transition: 0.6s;
	margin: 0px auto;
	display: table;
}
.prouct-sec .pro-img img {
	height: unset;
	object-fit: cover;
	width: 100%;
}
.product-box .pro-img:hover img {
	filter: grayscale(1);
}
.product-box .pro-detail {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	padding-top: 8px;
    padding-bottom: 8px;
}
.product-box .pro-detail h4 {
	font-size: 20px;
	color: #000000a1;
}
.product-box .pro-detail h4:hover {
	cursor: pointer;
	text-decoration: underline;
}
.product-slider .product-box .pro-img {
    display: unset;
}
@media (max-width: 1300px) {
	.prouct-sec .heading h2 {
		font-size: 40px;
		padding: 0 40px;
		line-height: 50px;
	}

}
@media (max-width: 1024px) {
	.discover .heading_wrapper {
		flex: 4;
	}
	.discover .view-all {
		flex: 1;
	}
	.prouct-sec {
		padding: 80px 0px 20px 0px;
	}
	.product-box .pro-detail h4 {
		font-size: 14px;
	}
	.discover .view-all a.lnk img {
		width: 60px;
		height: 60px;
	}
	.discover .view-all a.lnk {
		font-size: 16px;
		float: right;
	}
	.discover .heading_wrapper h2 {
		font-size: 46px;
	}
	.product-box .pro-detail {
		display: block;
		padding-top: 8px;
		padding-bottom: 8px;
	}
}
@media (max-width: 992px) {
	.discover .heading_wrapper h2 {
		font-size: 32px;
	}
	.discover .heading_wrapper {
		flex: 2 1;
	}
	.discover .view-all a.lnk {
		font-size: 14px;
		float: right;
	}
}
@media (max-width: 768px) {
.discover .heading_wrapper {
    flex: 2 1;
}
.product-box .pro-detail h4 {
    font-size: 11px;
}
.discover .heading_wrapper h2 {
    font-size: 32px;
}
}

@media (max-width: 576px) {
	/* .prouct-sec .product-box .pro-img {
		height: 160px;
	} */
	.prouct-sec .heading h2 {
		font-size: 26px;
		padding: 0px;
		line-height: 40px;
	}
	.discover {
		display: block;
		padding-top: 30px;
	}
	.discover .heading_wrapper h2 {
		font-size: 22px;
	}
	.discover .heading_wrapper h2:before {
		width: 40px;
		height: 40px;
		top: 12px;
	}
	.discover .heading_wrapper p {
		font-size: 13px;
	}
	.discover .view-all a.lnk {
		float: unset;
	}
	.prouct-sec {
		padding: 50px 0px 20px 0px;
	}
	.product-box .pro-detail h4 {
		font-size: 14px;
	}

}

/* Browse Product Css End Here */

/* Categories Sec Css Start Here */
.categories_sec {
	padding-bottom: 60px;
	position: relative;
}
.categories_sec .discover {
	padding-top: 20px;
}
.categories_sec .categories_box {
	background: #f2f2f2;
	padding: 20px 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	overflow: hidden;
}
.categories_sec .categories_box .content h2.title {
	font-size: 60px;
	color: #1e2912;
	/* font-family: "oriental-light"; */
	font-family: "Montserret";
	font-weight: 300;
}
.categories_sec .categories_box .content p {
	margin: 0;
	padding: 12px 0;
	color: #1e29126e;
	font-weight: 300;
	max-width: 280px;
}
.categories_sec .categories_box .content a.btn {
	background: #c23127;
	border-radius: 50px;
	color: #fff;
	padding: 7px 20px;
	transition: 0.6s;
}
.categories_sec .categories_box .content a.btn:hover {
	background: #fff;
	box-shadow: 0px 0px 0px 2px #c23127;
	color: #c23127;
}
.categories_sec .categories_box .content a.btn img {
	filter: brightness(0) invert(1);
	transition: 0.6s;
}
.categories_sec .categories_box .content a.btn:hover img {
	filter: unset;
}
.categories_sec .categories_box .img_box.frstImg {
    position: absolute;
    right: -30px;
    width: 60%;
    bottom: 0;
}
.categories_sec .frst_box {
	height: 100%;
}
.categories_sec .categories_box .img_box {
	position: absolute;
	right: 0;
	width: 40%;
	bottom: 0;
}
.categories_sec .categories_box .img_box.scndImg {
    padding: 0px;
}
.categories_sec .categories_box.scnd_box p {
	padding-top: 0;
}
.categories_sec .categories_box.thrd_box p {
	padding-top: 0;
}
.categories_sec .categories_box.scnd_box h2 {
	font-size: 46px !important;
}
.categories_sec .categories_box.thrd_box h2 {
	font-size: 46px !important;
}
.categories_sec .spacer {
	padding: 10px 0;
}
.categories_sec .before {
	position: absolute;
	z-index: -1;
	bottom: -60%;
	filter: grayscale(1);
}
/* Categories Sec Css End Here */

@media (max-width: 1366px) {
	.categories_sec .categories_box .content h2.title {
		font-size: 40px !important;
		line-height: normal;
	}
}
@media (max-width: 1024px) {
	.categories_sec .categories_box .content h2.title {
		font-size: 46px;
	}
	.categories_sec {
		padding-bottom: 0px;
	}
}
@media (max-width: 992px) {
	.categories_sec .categories_box .img_box.frstImg {
		right: 10px;
		width: 26%;
		bottom: -20px;
	}
}
@media (max-width: 768px) {
	.mb-768{
		margin-bottom: 16px;
	}
	.spacer-768{
		margin-bottom: 20px;
	}
	.categories_sec .categories_box .img_box.frstImg {
		right: 0px;
		width: 30%;
		bottom: -20px;
	}
	.categories_sec .categories_box .content p {
		max-width: 300px;
	}
	.categories_sec .categories_box .content h2.title {
		font-size: 32px !important;
	}
}
@media (max-width: 576px) {
	.categories_sec .categories_box {
		padding: 20px;
	}
	.categories_sec .categories_box .img_box.frstImg {
		display: none;
	}
	.categories_sec .categories_box .content h2.title {
		line-height: normal;
	}
	.categories_sec .categories_box .img_box.scndImg {
		display: none;
	}
	.categories_sec .categories_box .img_box {
		display: none;
	}
}

/* How It Work Css Start Here */
.how_it_work {
	position: relative;
}
.how_it_work .wor_box {
	padding: 0 50px;
	text-align: center;
	position: relative;
}
.how_it_work .wor_box .img_box {
    height: 260px;
    width: 260px;
    overflow: hidden;
    border-radius: 50%;
    border: 3px solid #c23127;
    margin: auto;
}
.how_it_work .wor_box .img_box img {
	/* border: 3px solid #c23127;
	border-radius: 50%;
	transition: 0.6s; */
	transition: 0.6s;
    height: 260px;
    width: 260px;
}
.how_it_work .wor_box:hover .img_box img {
	transform: rotate(45deg);
}
.how_it_work .wor_box .content {
	padding-top: 25px;
}
.how_it_work .wor_box .content h4 {
	color: #c23127;
	font-size: 30px;
	font-family: "falmera";
}
.how_it_work .wor_box .content h5 {
	color: #000000;
	font-family: "SF UI Display", sans-serif;
	font-size: 20px;
}
.how_it_work .wor_box .content h5 span {
	font-size: 16px;
}
.how_it_work .wor_box .arrow {
	position: absolute;
	right: -73px;
	bottom: 53%;
}
.how_it_work .wor_box .arrow img {
	width: 70%;
}
.how_it_work .after {
	position: absolute;
	right: 0;
	bottom: -60%;
	filter: grayscale(1);
	z-index: -1;
}
/* How It Work Css End Here */

@media (max-width: 1024px) {
	.how_it_work .wor_box .img_box {
		margin: auto;
	}
	.how_it_work .wor_box .arrow {
		display: none;
	}
	.how_it_work .wor_box {
		padding: 0px;
	}
	.how_it_work {
		padding-bottom: 0px!important;
	}
}
@media (max-width: 992px) {
	.how_it_work .wor_box .img_box {
		height: 200px;
		width: 200px;
	}
	.how_it_work .wor_box .img_box img {
		height: 200px;
		width: 200px;
	}

}
@media (max-width: 768px) {
	.how_it_work .wor_box .img_box img {
		height: 200px;
		width: 200px;
	}
	.how_it_work .wor_box .img_box {
		height: 200px;
		width: 200px;
	}
}
@media (max-width: 576px) {
	.how_it_work .wor_box {
		margin-bottom: 20px;
	}
	.how_it_work .wor_box .content {
		padding-top: 10px;
	}
}
/* Blog Sec Css Start Here */
.blog_sec {
    padding: 0px 0px 50px 0;
    position: relative;
}
.blog_sec .blog-before {
	filter: grayscale(1);
	position: absolute;
	bottom: -90px;
	z-index: -1;
}
.blog_sec .blog_box {
	position: relative;
	margin: 0 10px;
}
.blog_sec .blog_box .blog-img figure {
	position: relative;
	overflow: hidden;
	margin-bottom: 0;
}
.blog_sec .blog_box .blog-img figure:before {
	content: "";
	background: #c231279e;
	width: 100%;
	height: 100%;
	position: absolute;
	border-radius: 0 50%;
	transition: 0.6s;
	transform: scale(0);
}
.blog_sec .blog_box:hover .blog-img figure:before {
	transform: scale(1);
}
.blog_sec .blog_box .blog-img img {
	height: 300px;
	object-fit: cover;
	width: 100%;
}
.blog_sec .blog_box .blog-content {
	background: #ececec;
	padding: 15px 25px;
	transition: 0.6s;
}
.blog_sec .blog_box:hover .blog-content {
	background: #c23127;
}
.blog_sec .blog_box .blog-content h4.date {
	font-size: 13px;
	color: #434343bf;
	transition: 0.6s;
}
.blog_sec .blog_box:hover .blog-content h4.date {
	color: #fff;
}
.blog_sec .blog_box .blog-content h3.name {
	font-size: 22px;
	color: #434343;
	line-height: 27px;
	transition: 0.6s;
	display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.blog_sec .blog_box:hover .blog-content h3.name {
	color: #fff;
}
.blog_sec .blog_box .blog-content p {
	font-size: 18px;
	color: #000000c4;
	font-weight: 300;
	padding-top: 5px;
	transition: 0.6s;
	display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.blog_sec .blog_box:hover .blog-content p {
	color: #fff;
}
.blog_sec .blog_box .blog-content .button-group a.lnk {
	font-size: 20px;
	color: #c23127;
	text-decoration: none;
	text-transform: uppercase;
	transition: 0.6s;
}
.blog_sec .blog_box:hover .button-group a.lnk {
	color: #fff;
}
section.blog_sec .blog_slider .slick-slide {
    padding: 0 10px;
}

@media (max-width: 1024px) {
	.blog_sec {
		padding: 0px ;
	}
	.blog_sec .blog_box .blog-content h3.name {
		font-size: 18px;
	}
	.blog_sec .blog_box .blog-content p {
		font-size: 16px;
	}
	.blog_sec .blog_box .blog-content .button-group a.lnk {
		font-size: 16px;
	}
}
@media (max-width: 768px){
	.blog_sec .blog_box .blog-content {
		padding: 15px;
	}
}

/* Blog Sec Css End Here */

/* Feeds Css Start Here */
.social_sec {
	position: relative;
	padding-bottom: 80px;
}
.social_sec ul.social-icons {
	padding: 0;
	margin: 0;
	list-style: none;
}
.social_sec ul.social-icons li {
	display: inline-block;
	margin-left: 6px;
}
.social_sec ul.social-icons li a {
	background: #c23127;
	width: 40px;
	display: block;
	height: 40px;
	border-radius: 50%;
	color: #fff;
	font-size: 20px;
	text-align: center;
	line-height: 40px;
	transition: 0.6s;
}
.social_sec ul.social-icons li a img {
    width: 35px;
}
.social_sec ul.social-icons li:hover a {
	background: transparent;
	box-shadow: 0px 0px 0px 2px #c23127;
	color: #c23127;
}
.social_sec .discover {
	padding-bottom: 40px;
}
.social_sec figure {
	margin: 0 6px;
	position: relative;
}
.social_sec figure:before {
	content: "";
	background: #c2312787;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	border-radius: 0px 50% 0px 50%;
	transform: scale(0);
	transition: 0.6s;
}
.social_sec figure:hover:before {
	transform: scale(1);
}
.social_sec figure img {
	width: 100%;
	height: 300px;
	object-fit: cover;
}
/* Feeds Css End Here */

/* Newsletter Css Start Here */
.newsletter-sec {
	background: #c23127;
	padding: 10px 0px;
}
.newsletter-sec .heading h2 {
	font-size: 24px !important;
	color: #fff;
}
.newsletter-sec form {
	display: flex;
	align-items: center;
}
.newsletter-sec form .form-group {
	flex: 1;
}
.newsletter-sec form .form-control {
	background: transparent;
	border-radius: 0;
	border: 0;
	border-bottom: 1px solid #fff;
	box-shadow: none;
	outline: none;
	color: #fff;
	padding: 6px 0px;
}
.newsletter-sec form .form-control::placeholder {
	color: #ffffff7a;
}
.newsletter-sec form button.btn {
	transition: 0.6s;
}
.newsletter-sec form button.btn:hover {
	transform: translateX(10px);
}
@media (max-width: 1300px) {
	.newsletter-sec .heading h2 {
		font-size: 25px  !important;
		line-height: 35px !important;
	}
}
@media (max-width: 1024px) {
	.newsletter-sec .heading h2 {
		font-size: 22px  !important;
		line-height: 30px !important;
	}
}

@media (max-width: 576px) {
	.newsletter-sec .heading h2 {
		font-size: 18px  !important;
		line-height: 26px !important;
	}
	footer .scnd-content {
		margin: 14px 0px !important;
		display: table;
	}
}
/* Newsletter Css End Here */

/* Footer Css Start Here */
footer {
	padding: 100px 0 80px;
	background-image: url("../images/footer-back.png");
	background-size: cover;
	background-position: center center;
}
footer .footer-content .head h4 {
	color: #1e2912;
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 500;
	padding-bottom: 10px;
}
footer .footer-content p {
	max-width: 220px;
}
footer .footer-content ul.icons {
	padding: 0;
	margin: 0;
	list-style: none;
}
footer .footer-content ul.icons li {
	display: inline-block;
}
footer .footer-content ul.icons li a {
	background: #c23127;
	width: 35px;
	display: block;
	height: 35px;
	border-radius: 50%;
	color: #fff;
	font-size: 16px;
	text-align: center;
	line-height: 35px;
	transition: 0.6s;
	margin-right: 3px;
}
footer .footer-content ul.icons li:hover a {
	transform: translateY(-10px);
}
footer .footer-content ul.menus {
	padding: 0;
	margin: 0;
	list-style: none;
}
footer .footer-content ul.menus li {
	margin-bottom: 9px;
	display: flex;
    gap: 5px;
}
footer .footer-content ul.menus li h6 {
    width: 95px;
}
footer .footer-content ul.menus li a {
	color: #000;
	text-decoration: none;
	width: 100%;
}
footer .big-logo {
	padding-top: 70px;
	padding-bottom: 20px;
}
footer .big-logo h1 {
	font-size: 140px;
	text-align: center;
	color: #c23127;
	line-height: 140px;
}
footer .copyright {
	text-align: center;
}
footer .copyright p {
	margin: 0;
	color: #1e29129c;
	text-transform: uppercase;
	letter-spacing: 1px;
}
footer .scnd-content {
	margin: 0px auto;
	display: table;
}
footer .thrd-content {
	margin: 0px 0 0 auto;
	display: table;
}
footer .footer-content ul.icons li a i {
    font-size: 17px;
}
@media (max-width: 1300px) {
	footer .big-logo h1 {
		font-size: 135px;
	}
}
@media(max-width:1100px){
	footer .footer-content ul.menus li h6{
		width: 95px;
		font-size: 14px;
	}
	footer .footer-content ul.menus li{
		gap: 19px;
	}
	footer .footer-content ul.menus li a{
		width: 100%;
		font-size: 14px;
	}
	footer .footer-content p {
		max-width: 220px;
		font-size: 14px;
	}
}
@media(max-width:992px){
	.banner_sec .banner_content .content_dv h1 {
		font-size: 56px;
	}
}
@media(max-width:767px){
	footer .big-logo h1 {
			
	}
}
@media(max-width:741px){
	footer .scnd-content {
		margin: 30px 0px;
	}
	footer .thrd-content {
		margin: 0px;
	}
	footer .big-logo {
		padding-top: 0px;
		padding-bottom: 0px;
	}
	footer .big-logo h1 {
		font-size: 60px;
	}
}
@media(max-width:425px){
	footer .scnd-content {
		margin: 14px 0px;
		/* margin-top: 18px;
		margin-bottom: 12px; */
		display: table;
	}
	.banner_sec .banner_content .content_dv h1 {
		font-size: 25px;
	}
	footer .big-logo h1 {
		font-size: 80px;
	}
	.dntn_prod a img.img-fluid {
		width: 100%;
		height: 300px;
	}
	footer {
	padding: 40px 0 80px;
	}
	footer .footer-content p {
		max-width: 100%;
	}
	footer .scnd-content {
		margin: 25px 0px;
	}
	footer .footer-content ul.menus li h6 {
		width: 100px;
	}
	footer .big-logo h1 {
		font-size: 40px !important;
		line-height: normal;
	}
	footer .big-logo {
		padding-top: 30px;
		padding-bottom: 20px;
	}
	footer {
		padding: 40px 0 40px;
	}
	footer .copyright p {
		font-size: 13px;
	}
}

/* @media(max-width:390px){
	footer .big-logo h1 {
		font-size: 70px;
	}
	.banner_sec .banner_content .content_dv h1 {
		font-size: 25px;
	}
}
@media(max-width:320px){
	footer .big-logo h1 {
		font-size: 60px;
	}
} */
/* Footer Css End Here */

/* Prelaoder */
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	overflow: hidden;
	background: #c23127;
}
#preloader:before {
	content: "";
	position: fixed;
	top: calc(50% - 30px);
	left: calc(50% - 30px);
	border: 6px solid #f2f2f2;
	border-top: 6px solid #c23127;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	-webkit-animation: animate-preloader 1s linear infinite;
	animation: animate-preloader 1s linear infinite;
}
@-webkit-keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
/* loader css start here */
.graph-container {
	width: 145px;
	margin-left: auto;
	margin-right: auto;
	display: block;
 }
 
 .graph-donut {
	position: relative;
	display: inline-block;
	width: 160px;
	text-align: center;
 }
 
 .graph-donut canvas {
	position: absolute;
	top: 3px;
	left: 0;
 }
 
 .graph-title {
	margin-top: 75px;
	text-align: center;
	font-size: 18px;
	color: #453d33;
 }
 
 .percent {
	display: inline-block;
	font-size: 16px;
	color: #000;
	/* margin-top: 65px; */
	border: 2px solid #e3e5e6;
    padding: 1rem 1.5rem;
	border-top: 0;
	border-bottom: 0;
	/* margin-left: 3rem; */
	/* margin-top: 3px; */
	margin-bottom: 3px;
	padding-top: 0;
	margin-bottom: 7px;
 }
 
 .percent:after {
	content: '%';
 }
 .dntn_prod_desc {
    display: flex;
    align-items: center;
	text-align: center;
	justify-content: center;
	border: 1px solid #e3e5e6;
	border-left: 0;
	border-right: 0;
	border-bottom: 0;
	padding: 5px;
}
.dntn_prod {
    border: 1px solid #e3e5e6;
	margin: 2rem 0;
}
.pledged_dv p {
    margin-bottom: 0;
}
.dntn_prod h4 {
    padding: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.dntn_prod a img.img-fluid {
    width: 415px;
    height: 370px;
}
.graph-donut span.bott_txt {
    position: absolute;
    left: 60px;
    bottom: 2px;
	font-size: 12px;
    font-style: italic;
    color: #70777c;
}
span.bott_txt {
	font-size: 12px;
    font-style: italic;
    color: #70777c;
}
.dntn_prod_desc canvas {
  width: 40px !important;
  height: 40px !important;
}
.donutcanva {
  display: flex;
  align-items: center;
}
@media(max-width:1100px){
	.dntn_prod a img.img-fluid {
		width: 100%;
		height: 300px;
	}
}
@media(max-width:991px){
	.dntn_prod a img.img-fluid {
		width: 100%;
		height: 200px;
	}
	.graph-donut canvas{
		left: 15px;
	}
}
@media(max-width:767px){
	.dntn_prod a img.img-fluid {
		width: 100%;
		height: 370px;
	}
}
@media(max-width:768px){
	footer .footer-content ul.menus li h6 {
		width: 115px;
	}

	.percent {
		padding: 0px .5rem 1rem .5rem;
		font-size: 15px;
	}
	.graph-container {
		width: 80px;
	}
	.graph-donut {
		width: 80px;
	}
	.graph-donut span.bott_txt {
		left: 20px;
	}
	.pledged_dv p {
		font-size: 15px;
	}
	.dntn_prod h4 {
		padding: 10px;
		font-size: 20px;
	}
}
@media(max-width:576px){
.graph-container {
    width: 145px;
}
.percent {
    padding: 0px 1rem 1rem;
}
.graph-donut span.bott_txt {
    left: 25px;
}
}
/* loader css end here */