/* Blog Css Start Here */
.blog-detail .before {
    position: absolute;
    top: 30%;
    left: 0;
    z-index: -1;
    filter: grayscale(1);
  }
  .blog-detail {
    position: relative;
    padding: 60px 0px;
  }
  .blog-detail .img-box img {
    height: 380px;
    width: 100%;
    object-fit: cover;
  }
  .blog-detail p {
    line-height: 18px;
    font-weight: 300;
    font-size: 14px;
  }
  .blog-detail .more-detail {
    margin-top: 45px;
  }
  .blog-detail .content-wrapper h4 {
    color: #000000;
    font-size: 24px;
    line-height: 29px;
  }
  .blog-detail .after {
    position: absolute;
    bottom: 10%;
    right: 0;
    filter: grayscale(1);
  }
  /* Blog Css Start Here */