/* About Css Start Here */
.about_sec {
    position: relative;
    padding: 60px 0px;
  }
  .about_sec .before {
    position: absolute;
    top: 30%;
    left: 0;
    filter: grayscale(1);
    z-index: -1;
  }
  .about_sec .content-wrapper h2 {
    line-height: 56px;
    margin-bottom: 20px;
  }
  .about_sec .content-wrapper p {
    font-size: 15px;
    color: #00000096;
    /* line-height: 17px; */
    font-weight: 300;
  }
  .about_sec .content-wrapper .button-group a.btn {
    background: #c23127;
    color: #fff;
    text-transform: uppercase;
    padding: 8px 15px;
    border-radius: 50px;
    transition: 0.6s;
  }
  .about_sec .content-wrapper .button-group a.btn img {
    filter: brightness(0) invert(1);
    margin-left: 15px;
    transition: 0.6s;
  }
  .about_sec .content-wrapper .button-group a.btn:hover img {
    filter: unset;
  }
  .about_sec .content-wrapper .button-group a.btn:hover {
    background: transparent;
    box-shadow: 0px 0px 0px 1px #c23127;
    color: #c23127;
  }
  .about_sec .img_box figure {
    position: relative;
    overflow: hidden;
  }
  .about_sec .img_box figure:before {
    content: "";
    background: #c23127a3;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    border-radius: 40% 0;
    transition: 0.7s;
    transform: scale(0);
  }
  .about_sec .img_box:hover figure:before {
    transform: scale(1);
  }
  .about_sec .after {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  .how_it_work {
    padding-bottom: 60px;
  }
  /* About Css End Here */