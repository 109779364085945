/* Profile Css Start Here */
.notFound-container{
	text-align: center;
}
.hide {
	display: none;
}
.show {
	display: block;
}
.profile-sec .before {
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
	filter: grayscale(1);
}
.profile-sec {
	padding: 60px 0;
}
.profile-sec .heading {
	padding-bottom: 50px;
	text-align: center;
}
.profile-sec .heading h2 {
	color: #c23127;
	font-size: 80px;
	text-transform: uppercase;
}
.profile-sec .sidebar {
	background: #f4f4f4;
	padding: 40px 0 15px;
}
.profile-sec .sidebar .user-info {
	text-align: center;
}
.profile-sec .sidebar .user-info .profile-image-cont {
    height: 180px;
    width: 180px;
    overflow: hidden;
    border-radius: 50%;
    text-align: center;
    margin: auto !important;
    object-fit: contain;
}
.profile-sec .sidebar .user-info .profile-image-cont img{
	height: 180px;
    width: 180px;
}
/* .profile-sec .sidebar .user-info .img-box img {
	height: 180px;
    width: 180px;
} */
.profile-sec .sidebar .user-info .info h3 {
	font-size: 33px;
	color: #c23127;
	font-weight: 600;
	margin: 0;
}
.profile-sec .sidebar .user-info .info h5 {
	color: #0000009e;
	font-size: 18px;
	font-weight: 300;
}
.profile-sec .sidebar .user-info .info {
	padding: 15px 0;
}
.profile-sec .sidebar .nav {
	margin: 0 !important;
}
.profile-sec .sidebar button.nav-link {
	border-radius: 0;
	padding: 14px 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: "Montserrat";
	font-size: 18px;
	border-bottom: 1px solid #70707087;
	color: #00000078;
	font-weight: 500;
}
.profile-sec .sidebar button.nav-link.active {
	background: #c23127;
	color: #fff;
}
.profile-sec .sidebar button.nav-link.active img {
	filter: unset;
}
.profile-sec .sidebar button.nav-link img {
	filter: brightness(0.5);
}
.profile-sec .sidebar button.logout {
	border: 0;
	background: transparent;
	padding: 14px 40px;
	text-align: left;
	font-family: "Montserrat";
	font-size: 18px;
	color: #00000078;
	font-weight: 500;
}
/* Profile Css End Here */

/* First Tab Content Css Start Here */
.profile-sec .tab-content {
	height: 100%;
	background: #f4f4f4;
	padding-bottom: 40px;
}
.profile-sec .tab-box {
	padding: 0 40px;
}
.profile-sec .tab-box .box {
	padding-top: 40px;
}
.profile-sec .tab-box .box .heading {
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 20px;
}
.profile-sec .tab-box .box .heading h3 {
	font-size: 30px;
	color: #c23127;
	font-weight: 600;
	line-height: 40px;
}
.profile-sec .tab-box .box .heading button.edit {
	color: #c23127;
	padding: 0;
	border: 0;
	font-size: 15px;
	font-weight: 600;
	outline: none;
	background: transparent;
	box-shadow: none;
}
.profile-sec .tab-box .box .heading button.edit span {
	margin-left: 5px;
}
.profile-sec .tab-box .box .form-group {
	margin-bottom: 15px;
}
.profile-sec .tab-box .box .form-group label {
	font-size: 15px;
	color: #2a263d99;
	margin-bottom: 5px;
}
.profile-sec .tab-box .box .form-group .form-control {
	border: 1px solid #707070a6;
	border-radius: 50px;
	padding: 13px 30px;
	background: transparent;
	box-shadow: none;
	outline: none;
	font-size: 18px;
}
.profile-sec .tab-box .mr-20 {
	margin-right: 20%;
}
.profile-sec .tab-box .button-group button.btn {
	width: 100%;
	background: #c23127;
	color: #fff;
	text-transform: uppercase;
	padding: 10px 10px;
	border-radius: 50px;
	font-size: 16px;
}
/* First Tab Content Css End Here */

/* Second Tab Css Start Here */
.profile-sec .tab-box .container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.profile-sec .tab-box span.checkmark {
	position: unset;
}
.profile-sec .tab-box label.container {
	padding: 0;
}
.profile-sec .tab-box label.container .select-address {
	border: 2px solid #b5b5b5;
	padding: 20px 30px;
	position: relative;
	overflow: hidden;
	margin-bottom: 12px;
}
.profile-sec
	.tab-box
	label.container
	input:checked
	~ span.checkmark
	.select-address {
	box-shadow: 0px 0px 0px 2px #c23127;
	border-color: #fff;
	border-radius: 0;
}
.profile-sec .tab-box .select-address .radio-btn span {
	width: 15px;
	height: 15px;
	background: #fff;
	position: absolute;
	left: 0;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0px 0px 0px 1px #c23127;
	top: 10px;
	margin: 20px 20px;
}
.profile-sec
	.tab-box
	label.container
	input:checked
	~ span.checkmark
	.select-address
	.radio-btn
	span {
	background: #c23127;
}
.profile-sec .tab-box .select-address {
	position: relative;
}
/* .profile-sec .tab-box .select-address .address-detail {
	padding-left: 20px;
	margin-left: 30px;
	border-left: 1px solid #7070705c;
} */
.profile-sec .tab-box .select-address .address-detail h5 {
	width: 100%;
	display: flex;
	padding: 4px 0;
	align-items: center;
}
.profile-sec .tab-box .select-address .address-detail h5 span.property {
	font-size: 18px;
	color: #000;
	font-weight: 300;
}
.profile-sec .tab-box .select-address .address-detail h5 span.value {
	margin-left: 20px;
	font-weight: 300;
	color: #000000;
	font-size: 17px;
}
.profile-sec .tab-box .select-address .button-group {
	position: absolute;
	bottom: 20px;
	right: 20px;
}
.profile-sec .tab-box .select-address .address-detail button.btn {
	background: #e7e7e7;
	color: #7d7d7d;
	font-size: 15px;
	font-weight: 500;
	text-transform: capitalize;
	opacity: 1;
	border-radius: 0;
	padding: 6px 20px;
}
.profile-sec .tab-box .select-address .address-detail button.lnk {
	background: transparent;
	border: 0;
	font-size: 15px;
	color: #1473e6;
	text-decoration: underline;
	margin-left: 10px;
}
.profile-sec .tab-box .add-new-address {
	padding-top: 20px;
	position: relative;
}
.profile-sec .tab-box .save-address {
	margin-top: 40px;
}
.profile-sec .tab-box .box .deafult {
	display: flex;
	align-items: center;
	position: absolute;
	right: 20px;
	bottom: 10px;
}
.profile-sec .tab-box .box .deafult span.dot {
	width: 16px;
	height: 13px;
	/* background: #c23127; */
	display: block;
	border-radius: 50%;
	border: 2px solid #f4f4f4;
	box-shadow: 0px 0px 0px 2px #286152;
}
.profile-sec .tab-box .box .deafult h5 {
	color: #1f2c35;
	font-size: 15px;
	margin: 0 20px 0 0px;
}
.profile-sec .tab-box label.container input:checked ~ span.checkmark span.dot {
	box-shadow: 0px 0px 0px 2px #c23127;
	background: #c23127;
}
.profile-sec .tab-box label.container .select-address .actions a {
	color: #000000;
	font-size: 20px;
	margin-left: 10px;
}
.profile-sec .tab-box label.container .select-address .actions {
	position: absolute;
	right: 20px;
	top: 30px;
}
/* Second Tab Css End Here */

/* Third Css Start Here */
.profile-sec .tab-content .sixth-box {
	padding: 0;
}
.profile-sec .tab-content .sixth-box .heading {
	padding: 0 40px 20px;
}
.profile-sec .tab-content .donation-table thead tr {
	background: #c23127;
	color: #fff;
}
.profile-sec .tab-content .donation-table thead tr th {
	font-weight: 500;
	text-align: center;
}
.profile-sec .tab-content .donation-table td {
	vertical-align: middle;
	text-align: center;
	/* padding: 25px 0; */
	border-bottom: 1px solid #707070;
	padding: 1rem 10px;
}
.profile-sec button.btn.btn-primary {
	background: transparent;
	border: 0;
	padding: 0;
	text-decoration: underline;
	color: #0d6efd;
	box-shadow: none;
	outline: none;
}
.profile-sec .tab-content .donation-table td .status {
	display: flex;
	align-items: center;
	justify-content: center;
}
.profile-sec .tab-content .donation-table td .status span {
	margin-right: 10px;
	color: #00bc26;
	font-size: 16px;
}
.profile-sec .tab-content .donation-table td .status figure {
    margin-bottom: 0;
}
.profile-sec .tab-content .donation-table td a {
	text-transform: uppercase;
	color: #2692ff;
	font-size: 14px;
	font-weight: 600;
}
.paginatio-count {
	text-align: center;
	margin-top: 40px;
}
.paginatio-count ul {
	padding: 0;
	list-style: none;
}
.paginatio-count ul li.pagi {
	display: inline-block;
	border: 2px solid #b6b6b6;
	width: 30px;
	height: 28px;
	text-align: center;
	border-radius: 5px;
	margin: 0px 2px;
	cursor: pointer;
}
.paginatio-count ul li.pagi a {
	text-decoration: none;
	color: #6e6e6e;
}
.paginatio-count ul li.pagi.active {
	border-color: #c23127;
}
.paginatio-count ul li.pagi.active a {
	color: #c23127;
}
.paginatio-count ul li.arrow {
	background: #c23127;
	border-color: #c23127;
}
.paginatio-count ul li.arrow a {
	color: #fff;
}
/* Third Css End Here */

/* Fourth Tab Css Start Here */
.profile-sec .tab-content .wishlist-box .heading {
	padding-left: 40px;
}
.profile-sec .tab-content .wishlist-box table.table thead {
	background: #c23127;
	color: #fff;
	text-align: center;
}
.profile-sec .tab-content .wishlist-box table.table tbody tr td {
    padding: 1rem 10px;
    text-align: center;
    vertical-align: middle;
    border-bottom: 1px solid #00000070;
}
.profile-sec .tab-content .wishlist-box table.table tbody tr td select{
	margin: 8px 2px;
}
.profile-sec .tab-content .wishlist-box table.table tbody tr {
	border-right: 1px solid #00000070;
}
.profile-sec .tab-content .wishlist-box table.table {
	vertical-align: middle;
}
.profile-sec .tab-content .wishlist-box table.table tbody tr td h4 {
	line-height: 21px;
	font-size: 15px;
	margin: 0;
}
.profile-sec .tab-content .wishlist-box table.table tbody tr .quantity input {
	width: 35%;
	margin: 0px auto;
	border-radius: 0;
	background: #f2f2f2;
	border: 0;
	text-align: center;
}
.profile-sec .tab-content .wishlist-box table.table tbody tr h4.price {
	color: #c23127;
}
.profile-sec .tab-content .wishlist-box table.table tbody tr h4.subtotal {
	color: #c23127;
}
.profile-sec .tab-content .wishlist-box table.table tbody tr td figure img {
	margin: 0px auto 0 0;
	display: table;
	width: 70px;
	mix-blend-mode: darken;
}
.profile-sec .tab-content .wishlist-box table.table tbody tr td.quantityTh {
	width: 17%;
}
.profile-sec .tab-content .wishlist-box table.table tbody tr td .delete {
	cursor: pointer;
}
.profile-sec
	.tab-content
	.wishlist-box
	table.table
	tbody
	tr
	td
	h4.variation-type {
	text-align: center;
	/* padding-left: 70px; */
}
.profile-sec .tab-box.wishlist-box {
	padding: 0;
}
.profile-sec .wishlist-box {
	padding: 0;
}
.profile-sec .wishlist-box tr {
	border-right: 0 !important;
}
/* Fourth Tab Css End Here */
.profile-sec .after {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: -1;
	filter: grayscale(1);
}
/* Profile Css End Here */

/* Order Detail Css Start Here */
#orderDetail .modal-header {
	padding: 0;
	border: 0;
}
#orderDetail .modal-header button.btn-close {
	padding: 20px 20px;
	color: #000;
	opacity: 1;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 9;
}
#orderDetail .modal-dialog.modal-dialog-centered {
	max-width: 40%;
}
#orderDetail .modal-dialog.modal-dialog-centered .heading {
	padding-bottom: 30px;
	text-align: center;
	/* border-bottom: 1px solid #ddd; */
	padding-top: 10px;
}
#orderDetail .modal-body {
	padding: 0px 0;
}
#orderDetail .modal-dialog.modal-dialog-centered .heading h2 {
	font-size: 30px;
	line-height: 40px;
	margin: 0;
}
#orderDetail .modal-content {
	border-radius: 5px;
	border: 0;
}
#orderDetail .all-orders {
	height: 255px;
	overflow: auto;
	padding: 0 0px;
}
#orderDetail .all-orders::-webkit-scrollbar {
	width: 0.3em;
}
#orderDetail .all-orders::-webkit-scrollbar-thumb {
	background-color: #c23127;
}
#orderDetail .all-orders .order-detail {
    display: block;
    align-items: center;
    background: #fff;
    box-shadow: 0px 0px 10px #ddd;
    margin: 10px 13px;
    border-radius: 5px;
    padding: 10px;
}
#orderDetail .all-orders .order-detail .img-box img {
    width: 100%;
    margin-bottom: 20px;
    height: 200px;
}
#orderDetail .all-orders .order-detail .details {
	flex: 1;
	max-width: 100%;
}
#orderDetail .all-orders .order-detail .details h5 {
	font-size: 17px;
	color: #000;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
#orderDetail .all-orders .order-detail .details h5.status span:nth-child(2) {
	color: green;
}
#orderDetail ul.order-summary {
	padding: 0;
	margin: 30px 35px 0;
	list-style: none;
}
#orderDetail ul.order-summary li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 8px;
}
#orderDetail .button-group {
	text-align: center;
}
#orderDetail .button-group a.btn {
	color: #fff;
	padding: 7px 0;
	border-radius: 50px !important;
	width: 90%;
	margin: 40px auto 30px;
	text-align: center;
	background: #c23127;
	transition: 0.6s;
	border: 1px solid #c23127;
}
#orderDetail .button-group a.btn:hover {
	color: #c23127;
	background-color: transparent;
}
/* Order Detail Css End Here */

/* Order Detail Css Start Here */
#donationDetail .modal-header {
	padding: 0;
	border: 0;
}
#donationDetail .modal-header button.btn-close {
	padding: 20px 20px;
	color: #000;
	opacity: 1;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 9;
}
#donationDetail .modal-dialog.modal-dialog-centered {
	max-width: 40%;
}
#donationDetail .modal-dialog.modal-dialog-centered .heading {
	padding-bottom: 30px;
	text-align: center;
	/* border-bottom: 1px solid #ddd; */
	padding-top: 10px;
}
#donationDetail .modal-body {
	padding: 30px 0;
}
#donationDetail .modal-dialog.modal-dialog-centered .heading h2 {
	font-size: 30px;
	line-height: 40px;
	margin: 0;
}
#donationDetail .modal-content {
	border-radius: 5px;
	border: 0;
}
#donationDetail .all-orders {
	height: 160px;
	overflow: auto;
	padding: 0 0px;
}
#donationDetail .all-orders::-webkit-scrollbar {
	width: 0.3em;
}
#donationDetail .all-orders::-webkit-scrollbar-thumb {
	background-color: #c23127;
}
#donationDetail .all-orders .order-detail {
    display: flex;
    align-items: center;
    background: #fff;
    box-shadow: 0px 0px 10px #ddd;
    margin: 10px 30px;
    border-radius: 5px;
    padding: 10px;
}
#donationDetail .all-orders .order-detail .img-box img {
	max-width: 90px;
	margin-right: 20px;
}
#donationDetail .all-orders .order-detail .details {
	flex: 1;
}
#donationDetail .all-orders .order-detail .details h5 {
	font-size: 17px;
	color: #000;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
#donationDetail .all-orders .order-detail .details h5.status span:nth-child(2) {
	color: green;
}
#donationDetail ul.order-summary {
	padding: 0;
	margin: 30px 35px 0;
	list-style: none;
}
#donationDetail ul.order-summary li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 8px;
}
#donationDetail .button-group {
	text-align: center;
}
#donationDetail .button-group a.btn {
	color: #fff;
	padding: 7px 0;
	border-radius: 50px !important;
	width: 90%;
	margin: 40px auto 30px;
	text-align: center;
	background: #c23127;
	transition: 0.6s;
	border: 1px solid #c23127;
}
#donationDetail .button-group a.btn:hover {
	color: #c23127;
	background-color: transparent;
}
#donationDetail .all-orders .order-detail .details h5.status {
	color: green;
}
.table-reponsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
/* Order Detail Css End Here */

@media(max-width:768px){
	.profile-sec .heading h2 {
		font-size: 46px;
	}
}

@media(max-width:576px){
	.profile-sec .heading h2 {
		font-size: 32px;
	}
	.profile-sec .heading {
		padding-bottom: 10px;
	}
	.profile-sec .tab-box {
		padding: 0 20px;
	}
	.profile-sec .tab-box .mr-20 {
		margin-right: 0%;
	}
	.profile-sec .tab-box .select-address .address-detail h5 {
		display: block;
	}
	.profile-sec .tab-box .select-address .address-detail h5 span.property {
		font-size: 16px;
	}
	.profile-sec .tab-box .select-address .address-detail h5 span.value {
		margin-left: 0px;
		font-size: 16px;
	}
	.profile-sec .tab-box label.container .select-address {
		padding: 40px 10px;
	}
	.table-responsive table.table td {
		min-width: 175px;
	}
	#donationDetail .all-orders .order-detail {
		display: block;
		margin: 10px 20px;
	}
	#donationDetail .all-orders .order-detail .details h5 {
		font-size: 14px;
		margin-top: 10px;
	}
	.modal-content {
		padding: 18px 20px;
	}
	.modal-mainTitle {
		font-size: 24px !important;
	}
	#orderDetail .all-orders .order-detail {
		display: block;
	}
	#orderDetail .all-orders .order-detail .img-box img {
		max-width: 100%;
		margin-right: 0px;
		margin-bottom: 10px;
	}
	#orderDetail .all-orders .order-detail .details {
		flex: 1 1;
		max-width: 100%;
	}
	#orderDetail .all-orders .order-detail .details h5 {
		font-size: 14px;
	}
	#donationDetail .modal-body {
		padding: 0px;
	}
	#orderDetail ul.order-summary {
		padding: 0;
		margin: 30px 15px 0;
	}
}

@media(max-width:320px){
	.modal-content {
		padding: 18px 5px;
	}
	#orderDetail ul.order-summary li span{
		font-size: 12px;
	}
}
