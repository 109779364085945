/* Product Detail Css Start Here */
.addon-name-select{
	width: 348px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.custom-select {
	width: 120px !important;
	overflow: hidden !important;
	white-space: pre !important;
	text-overflow: ellipsis !important;
	-webkit-appearance: none !important;
}

.product-detail {
	padding: 60px 0px;
	position: relative;
}

.product-detail .before {
	position: absolute;
	left: -20%;
	z-index: -1;
	top: 25%;
	filter: grayscale(1);
}

.product-detail .product-images .single-image figure {
	background: #f4f4f4;
	/* margin-bottom: 10px; */
	border-radius: 20px;
	/* padding: 25px 0px; */

	height: 432px;
	width: 100%;
	overflow: hidden;
}

.product-detail .product-images .single-image img {
	border-radius: 10px;
	max-width: 100%;
	object-fit: cover;
	margin: 0px auto;
}

.product-detail .product-images .single-image img {
    border-radius: 10px;
    margin: 0 auto;
    object-fit: cover;
    height: 440px;
    width: 100%;
}

.product-detail .product-images .slider-images img {
    border-radius: 10px;
    width: 212px;
    height: 106px;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
}

.product-detail .product-images .slider-images figure {
	margin: 5px;
	background: #f4f4f4;
	padding: 0px;
	border-radius: 10px;
}

.product-detail .product-images .slider-images .slick-slide {
	padding: 0 5px;
}

.product-detail .details .header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	flex-wrap: wrap;
}

.product-detail .details .header h4 {
	color: #00000085;
	font-size: 25px;
	margin: 0;
}

.product-detail .details .header h3 {
	font-size: 35px;
	color: #000000d1;
	line-height: 45px;
}

.product-detail .details .header .price-tag {
	background: #c23127;
	border-radius: 5px;
	padding: 10px 20px;
}

.product-detail .details .header .price-tag h5 {
	color: #ffffff;
	font-size: 15px;
	margin: 0 0 0px;
}

.product-detail .details .header .price-tag h5 span.value {
	font-size: 20px;
}

.product-detail .details .header .price-tag p {
	font-size: 18px;
	font-weight: bold;
	color: #fff;
	margin: 0;
}

.product-detail .details .description h4 {
	color: #464646;
	font-size: 16px !important;
	font-weight: 500;
}

.product-detail .details .description p {
	color: #0000008a;
	font-weight: 300;
	line-height: 19px;
}

.product-detail .details .additional-info {
	padding-top: 15px;
	position: relative;
}

.product-detail .details .additional-info h4 {
	color: #464646;
	font-size: 22px;
	font-weight: 500;
	margin-bottom: 10px;
}

.product-detail .details .additional-info ul {
	padding: 0;
	list-style: none;
	padding-top: 5px;
}

.product-detail .details .additional-info ul li {
	color: #0000008a;
	font-weight: 400;
	font-size: 15px;
	margin: 0 0 4px;
	transition: 0.6s;
}

.product-detail .details .additional-info ul li:hover {
	color: #000;
}

.product-detail .details .additional-info .vertical-line {
	border: 1px solid #ddd;
	width: 1px;
	height: 60%;
	position: absolute;
	top: 30%;
	left: -10%;
	right: 0;
	margin: 0px auto;
}

.product-detail .details .variations .form-group .input-group label.add_container {
	width: auto;
}

.product-detail .details .variations .form-group .input-group label.add_container span.checkmark h5 {
	line-height: 30px;
	cursor: pointer;
}

.product-detail .details .variations .form-group .input-group label.add_container input:checked~span.checkmark h5 {
	border: 1px solid #000;
}

.product-detail .details .variations {
	margin-top: 15px;
}

.product-detail .details .variations .form-group {
	display: flex;
	align-items: center;
	margin-bottom: 11px;
}

.product-detail .details .variations .form-group .input-group label.add_container span.checkmark h5 {
	background: #f2f2f2;
	width: 80px;
	height: 30px;
	margin: 0px 8px;
	text-align: center;
	font-size: 14px;
}

.product-detail .details .variations .form-group .input-group label.add_container input:checked~span.checkmark h5.price {
	border-top: 0;
}

.product-detail .details .variations .form-group .input-group label.add_container input:checked~span.checkmark h5.count {
	border-bottom: 0;
}

.product-detail .details .variations .form-group label {
	width: 260px;
	color: #000000a6;
	font-weight: 500;
}

.product-detail .details .variations .form-group .quantity {
	display: flex;
	justify-content: center;
	align-items: center;
}

.product-detail .details .variations .form-group .quantity .minus {
	padding: 3px 12px;
	color: #fff;
	background-color: #c23127;
	border: unset;
}

.product-detail .details .variations .form-group .quantity .plus {
	padding: 3px 10px;
	color: #fff;
	background-color: #c23127;
	border: unset;
}

.product-detail .details .variations .form-group .quantity span {
	padding: 0px 12px;
}

.product-detail .details .variations .form-group .input-group {
	flex-wrap: wrap;
}

.product-detail .details .variations .form-group .input-group label.add_container {
	width: auto;
}

.product-detail .details .variations .form-group .input-group label.add_container-Selected .checkmark {
	border: 1px solid rgb(201, 10, 10);
}

.product-detail .details .variations .form-group .form-control {
	background: #f2f2f2;
	border: 0;
	border-radius: 0;
	color: #00000094;
	font-weight: 600;
	padding: 5px 10px;
	box-shadow: none;
	outline: none;
}

.product-detail .details .variations .form-group select.form-control {
	background-image: url(../images/caret.png);
	background-repeat: no-repeat;
	background-position: center right 10px;
	background-size: 14px;
}

.product-detail .details .donation {
	margin-top: 20px;
}

.product-detail .details .donation .title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.product-detail .details .donation .title a {
	color: rgb(134, 134, 134);
	text-decoration: none;
	font-size: 14px;
	font-weight: bold;
}

.product-detail .details .donation .title a:hover {
	color: rgb(73, 73, 73);
	text-decoration: underline;
	font-weight: bold;
}

.product-detail .details .donation .title h4 {
	color: #464646;
	font-size: 22px;
	font-weight: 500;
	margin-bottom: 10px;
}

.product-detail .details .donation .input-group {
	border: 1px solid #54544e;
	padding: 10px 10px;
	border-radius: 10px;
	text-align: center;
}

.product-detail .details .donation .input-group .form-group {
	display: flex;
	align-items: center;
}

.product-detail .details .donation .input-group .form-group label {
	width: 140px;
	color: #000000;
	font-weight: 600;
	font-size: 13px;
	margin-bottom: 0;
}

.product-detail .details .donation .input-group .form-group.br-right {
	border-right: 2px solid #54544e;
	padding-right: 10px;
	margin-right: 18px;
}

.product-detail .details .donation .input-group .form-group .form-control {
	width: auto;
	padding: 0 25px 0 0px;
	border: 0;
	outline: none;
	box-shadow: none;
	background-image: url(../images/caret.png);
	background-repeat: no-repeat;
	background-position: center right 10px;
	background-size: 14px;
	font-size: 12px;
}

.product-detail .details .button-group {
	margin-top: 30px;
}

.product-detail .details .button-group button.btn {
	background: #c23127;
	padding: 10px 40px;
	border-radius: 50px;
	color: #fff;
	font-size: 15px;
	text-transform: uppercase;
	transition: 0.6s;
	border: 1px solid #c23127;
}

.product-detail .details .button-group button.btn img {
	filter: brightness(0) invert(1);
	margin-left: 12px;
	transition: 0.6s;
}

.product-detail .details .button-group button.btn:hover {
	color: #c23127;
	background: transparent;
}

.product-detail .details .button-group button.btn:hover img {
	filter: unset;
}

.product-detail .details .map {
	margin-top: 50px;
	border: 1px solid #707070;
	border-radius: 10px;
	padding: 0px 10px 30px;
	background: #fff;
}

.product-detail .details .map .map-img img {
	width: 100%;
}

.product-detail .details .map .map-zones {
	text-align: center;
	margin-top: 25px;
}

.product-detail .details .map .map-zones h4 {
	font-size: 22px;
	color: #464646;
	font-weight: 600;
	margin: 0;
}

.product-detail .details .map .map-zones p {
	color: #0000008f;
}

.product-detail .details .map .map-zones ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.product-detail .details .map .map-zones ul li {
	display: inline-block;
	background: #f2f2f2;
	width: 30px;
	height: 30px;
	line-height: 30px;
	margin: 0px 2px;
}

.product-detail .details .map .map-zones ul li a {
	color: #000;
	text-decoration: none;
}

.product-detail .details .map .map-zones ul li.active {
	background: #9ecf74;
}

.product-detail .details .map .map-zones ul li.active a {
	color: #fff;
}

.product-detail .reviews_sec {
	margin-top: 70px;
}

.product-detail .reviews_sec .heading {
	padding-bottom: 30px;
}

.product-detail .reviews_sec .heading h3 {
	font-size: 35px;
	color: #000000;
}

.product-detail .reviews_sec .reviewDv {
	border-bottom: 1px solid #ddd;
}

.product-detail .reviews_sec .reviewDv:last-child {
	border: 0;
}

.product-detail .reviews_sec .reviewDv:last-child .reviews {
	border-bottom: 0;
}

.product-detail .reviews_sec .reviewDv .answersDv {
	padding: 15px 0 15px 100px;
	position: relative;
}

.product-detail .reviews_sec .reviewDv .answersDv .vertical-line {
	width: 1px;
	height: 90%;
	background: #ddd;
	position: absolute;
	left: 10%;
	top: 5%;
}

.product-detail .reviews_sec .reviewDv .reviews {
	display: flex;
	align-items: flex-start;
	border-bottom: 1px solid #ddd;
	padding: 15px 0px;
}

.product-detail .reviews_sec .reviewDv .reviews .user-img figure {
	width: 50px;
	height: 50px;
	overflow: hidden;
	border-radius: 50%;
}

.product-detail .reviews_sec .reviewDv .reviews .user-img img {
	/* max-width: 350px;
	border-radius: 50%; */
	height: 50px;
	width: 50px;
}

.product-detail .reviews_sec .reviewDv .reviews .detailDv {
	padding-left: 16px;
}

.product-detail .reviews_sec .reviewDv .reviews .detailDv .header {
	display: flex;
	align-items: center;
	padding-bottom: 10px;
}

.product-detail .reviews_sec .reviewDv .reviews .detailDv .header h5.name {
	font-size: 18px;
	color: #000000;
	margin: 0;
}

.product-detail .reviews_sec .reviewDv .reviews .detailDv .header p {
	color: #909090;
	font-size: 12px;
	margin: 0 0 0px 8px;
}

.product-detail .reviews_sec .reviewDv .reviews .detailDv p {
	color: #525252;
	font-size: 13px;
	margin-bottom: 0px;
}

.product-detail .reviews_sec .bottom-bar ul {
	padding: 0;
	list-style: none;
	margin: 0;
}

.product-detail .reviews_sec .bottom-bar .rating {
	display: flex;
    justify-content: center;
    align-items: center;
    align-items: flex-end;
}

.product-detail .reviews_sec .bottom-bar ul li {
	display: inline-block;
	color: #c23127;
}

.product-detail .reviews_sec .bottom-bar p {
	margin: 0 0 0 8px;
}

.product-detail .reviews_sec .bottom-bar {
	display: flex;
	align-items: center;
}

.product-detail .reviews_sec .bottom-bar .rplyDv {
	margin-left: 30px;
}

.product-detail .reviews_sec .bottom-bar .rplyDv a {
	color: #262262;
	text-decoration: none;
}

.product-detail .reviews_sec .answersDv .answer:last-child {
	border: 0;
	padding-bottom: 0;
}

.rate {
	float: left;
	height: 46px;
	padding: 0 10px;
}

.rate:not(:checked)>input {
	position: absolute;
	/* top: -9999px; */
}

.rate:not(:checked)>label {
	float: right;
	width: 1em;
	overflow: hidden;
	white-space: nowrap;
	cursor: pointer;
	font-size: 30px;
	color: #ccc;
}

.rate:not(:checked)>label:before {
	content: "★";
}

.rate>input:checked~label {
	color: #c23127;
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
	color: #c23127;
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
	color: #c23127;
}

.product-detail .post-review {
	margin-top: 70px;
}

.product-detail .post-review .header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
}

.product-detail .post-review .header .left-side {
	display: flex;
	align-items: center;
}

.product-detail .post-review .header .left-side p {
	margin: 0;
	color: #909090;
	font-size: 10px;
}

.product-detail .post-review .header .left-side h4 {
	margin: 0;
	color: #000000;
	font-size: 17px;
	line-height: 20px;
}

.product-detail .post-review .header .left-side .name {
	padding-left: 10px;
}

.product-detail .post-review .header .left-side figure {
	width: 65px;
	height: 65px;
	overflow: hidden;
	border-radius: 50%;
}

.product-detail .post-review .header .left-side img {
	/* border-radius: 50%;
	max-width: 50px; */
	height: 65px;
	width: 65px;
}

.product-detail .post-review textarea {
	border-radius: 0;
	resize: none;
	height: 110px;
	color: #00000052;
	border: 0;
	border-bottom: 1px solid #70707069;
	outline: none;
	box-shadow: none;
}

.product-detail .post-review textarea::placeholder {
	color: #00000052;
}

.product-detail .post-review button.btn {
	margin-top: 30px;
	background: #c23127;
	padding: 10px 40px;
	border-radius: 50px;
	color: #fff;
	font-size: 17px;
	text-transform: uppercase;
	transition: 0.6s;
	border: 1px solid #c23127;
}

.product-detail .post-review button.btn img {
	filter: brightness(0) invert(1);
	margin-left: 10px;
}

.product-detail .plant-video {
	margin-top: 70px;
}

.product-detail .plant-video h2 {
	color: #c23127;
	font-size: 60px;
	padding-bottom: 0px;
}

.product-detail .plant-video video {
	width: 100%;
	height: 310px;
}

.product-detail .plant-video .video-container {
	position: relative;
	overflow: hidden;
}

.product-detail .plant-video .video-container .play-button-wrapper {
	position: absolute;
	bottom: 0;
	height: 100%;
	justify-content: center;
	width: 100%;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
}

.product-detail .plant-video .video-container .play-button-wrapper img {
	cursor: pointer;
}

.frequently_bought {
	padding-bottom: 40px;
	padding-top: 34px;
}

.frequently_bought .heading {
	padding-bottom: 20px;
}

.frequently_bought .heading h2 {
	color: #c23127;
	font-size: 50px;
	line-height: 50p;
	font-size: 28px !important;
}

.frequently_bought .pro-img {
	border: 0;
	height: 130px;
	width: 100%;
	overflow: hidden;
	display:block;
}

.frequently_bought .pro-img img {
	border-radius: 10px;
	height: 130px;
	width: 100%;
}

.frequently_bought .pro-detail .name {
	width: 50%;
}

.frequently_bought .pro-detail h4 {
	/* font-size: 17px !important; */
	font-size: 13px !important;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.no_image_avatar_product_details{
	width: 100%;
    height: 350px;
    object-fit: contain;
    background-color: #fff;
}

.product-detail .after {
	position: absolute;
	z-index: -1;
	bottom: 49%;
	right: 0;
}
.description h3 {
    font-size: 16px !important;
    line-height: 22px;
    margin-bottom: 20px;
    color: #000;
}
/* .product-box:nth-child(1):after {
    content: "/";
} */
/* .product-box:last-child:after {
    content: "-";
} */
/* li:nth-child(3) p:after */
/* .pro_box:nth-child(3)::after{
	content: "" !important;
} */
.pro_box:after {
	content: "+";
	position: absolute;
	top: 40%;
	right: -19px;
	font-family: "fontAwesome";
	font-size: 26px;
	font-weight: bold;
}

.product-box {
	position: relative;
}

.plant-mixes .product-box figure {
	height: 200px;
	width: 200px;
	overflow: hidden;
}

.plant-mixes .product-box figure img {
	width: 200px;
	height: 200px;
}

.nutrients .product-box figure {
	height: 200px;
	width: 200px;
	overflow: hidden;
}

.nutrients .product-box figure img {
	height: 200px;
	width: 200px;
}

.kigibirds_sec .product-box figure {
	height: 200px;
	width: 200px;
	overflow: hidden;
}

.kigibirds_sec .product-box figure img {
	height: 200px;
	width: 200px;
}

.otherProducts .product-box figure {
	height: 180px;
	width: 180px;
	overflow: hidden;
}

.otherProducts .product-box figure img {
	height: 180px;
	width: 180px;
}

.all-plants .product-box figure {
	height: 200px;
	width: 200px;
	overflow: hidden;
}

.all-plants .product-box figure img {
	height: 200px;
	width: 200px;
}

.bulk_pricing h4 {
	color: #464646;
	font-size: 22px;
	font-weight: 500;
	margin-bottom: 10px;
}

.bulk_pricing ul li {
	color: #0000008a;
	font-weight: 400;
	font-size: 15px;
	margin: 0 0 4px;
	transition: 0.6s;
}

.bulk_pricing p {
	color: #0000008a;
	font-weight: 400;
	font-size: 15px;
}

/* growing zone css start here */
.header-box {
	display: inline-block;
	margin-left: 0;
	position: relative;
}

.header-box__growing-zone {
	/* padding: 1rem 1rem 3rem;
    background-color: #fff; */
	border-radius: 8px;
	box-shadow: 0 5px 15px #00000026;
	border: 1px solid #ebebeb;
	margin-top: 1rem;
	width: 100%;
}

.header-box__icon {
	display: inline-block;
	width: 35px;
	height: 35px;
	vertical-align: middle;
}

.header-box__content {
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	margin: 0 25px 0 5px;
	line-height: 1;
}

.header-box__growing-zone .header-box__growing-zone-num {
	color: #141414;
	font-size: 14px;
	font-weight: 600;
	display: block;
}

.header-box__growing-zone .header-box__city-state {
	color: #141414;
	font-size: 12px;
	display: block;
	margin-top: 4px;
}

.header-box__arrow--down {
	transform: rotate(-135deg);
	right: 15px;
	top: 45%;
}

.header-box__arrow:before {
	display: block;
	height: 200%;
	width: 200%;
	margin-left: -50%;
	margin-top: -50%;
	content: "";
	transform: rotate(45deg);
}

.header-box:hover .header-box__dropdown {
	display: block;
}

.header-box__dropdown {
	/* display: none;
    position: absolute;
    top: 53px;
    left: -1px;
	width: 300px; */
	padding: 15px;
	background-color: #fff;
	border-radius: 8px 0 8px 8px;
	/* z-index: 9999; */
	box-shadow: 0 10px 15px #00000026;
	border-bottom: 1px solid #ebebeb;
	border-left: 1px solid #ebebeb;
	border-right: 1px solid #ebebeb;
}

.header-box__dropdown .zone-box label {
	font-weight: 600;
	font-size: 14px;
	text-align: right;
	display: block;
}

.header-box__dropdown .zone-box input {
	float: right;
	width: 100%;
	margin: 10px 0;
	font-family: "SF UI Display", sans-serif;
	font-size: 15px;
}

.header-box__dropdown .zone-box button {
	width: 100%;
	margin-bottom: 0;
	/* margin-left: 10px; */
}

/* .header-box__dropdown .zone-box__content form {
    display: flex;
    align-items: center;
} */
.btn--default {
	color: #fff;
	background: #c23127;
}

.header-box__dropdown .zone-box a {
	text-align: center;
	font-size: 14px;
	display: block;
}

.zone-box__content .error {
	display: none;
}

.zone-box__content .error_show {
	color: red;
	text-align: center;
}

.zone-box__content input.invalid,
.zone-box__content textarea.invalid {
	border: 1px solid red;
}

.zone-box__content input.valid,
.zone-box__content textarea.valid {
	border: 1px solid green;
}

section.product-detail.birdsprodetail .after {
	bottom: -40%;
}

section.product-detail.birdsprodetail .before {
	top: 55%;
}
img.__mce_add_custom__ {
    max-width: 100%;
}
.donation .form-group {
    margin: 0px;
}
/* growing zone css end here */
@media(max-width:1367px) {
	.product-detail .details .donation .input-group .form-group label {
		width: 130px;
	}
	.product-detail .details .donation .input-group .form-group.br-right {
		margin-right: 5px;
	}
}

@media(max-width:1199px) {
	.product-detail .details .button-group {
		margin-top: 30px;
		display: flex;
		gap: 10px;
		flex-direction: column;
	}

	.product-detail .details .button-group button.btn {
		width: 100%;
	}
}

@media(max-width:1024px) {
	.product-detail .details .donation .input-group .form-group label {
		width: 87px;
	}
	.product-detail .details .donation .input-group .form-group .form-control {
		width: 120px;
	}
}
@media(max-width:992px) {
	.product-detail .details .donation .input-group .form-group label {
		width: 200px;
	}
}
@media(max-width:768px) {
	.custom-select {
		width: auto !important;
	}
	
	.product-detail .details .header {
		margin-top: 30px;
	}
	.product-detail .details .donation .input-group .form-group label {
		width: 120px;
	}
	.product-detail .details .donation .input-group .form-group .form-control {
		width: 185px;
	}
}

@media(max-width:767px) {
	.pro_box:after {
		content: "+";
		position: absolute;
		bottom: -35px;
		right: unset;
		font-family: "fontAwesome";
		font-size: 26px;
		font-weight: bold;
		left: 50%;
		top: unset;
	}

	.product-box {
		margin-bottom: 3rem;
	}
}
@media(max-width:741px) {
	.product-detail .details .donation .input-group .form-group .form-control {
		width: 100px !important;
	}
}

@media(max-width:576px) {
	.product-detail .post-review .header {
		display: block;
	}
	.product-detail .reviews_sec {
		margin-top: 30px;
	}
	.product-detail .post-review {
		margin-top: 30px;
	}
	.product-detail .details .variations .form-group label {
		width: 100%;
	}
	.product-detail .details .variations .form-group {
		display: block;
	}
	.product-detail .details .donation .input-group .form-group label {
		width: 80px;
		text-align: left;
	}
	.product-detail .details .donation .input-group .form-group.br-right {
		border-right: 0px solid #54544e;
	}
	.frequently_bought .heading h2 {
		line-height: 40px;
		font-size: 28px !important;
	}
	.product-detail .reviews_sec .heading {
		padding-bottom: 0px;
	}
}

@media(max-width:425px) {
	footer .big-logo h1 {
		font-size: 90px;
	}
}

@media(max-width:320px) {
	footer .big-logo h1 {
		font-size: 70px;
	}
}

.button-group.frequentlybtn .btn {
	background: #c23127;
	padding: 10px 40px;
	border-radius: 50px;
	color: #fff;
	font-size: 15px;
	text-transform: uppercase;
	transition: 0.6s;
	border: 1px solid #c23127;
}

.button-group.frequentlybtn .btn:hover {
	color: #c23127;
	background: transparent;
}

.button-group.frequentlybtn {
	text-align: center;
	margin-top: 2rem;
}

.button-group.frequentlybtn .btn img.img-fluid {
	filter: brightness(0) invert(1);
	margin-left: 12px;
	transition: 0.6s;
}

.button-group.frequentlybtn .btn:hover img.img-fluid {
	filter: unset;
	-webkit-filter: unset;
}

/* Product Detail Css End Here */