/* Site Setting Css Start Here */
.site-setting {
    position: relative;
    padding: 60px 0;
    text-align: center;
  }
  .site-setting .before {
    filter: grayscale(1);
    position: absolute;
    top: 20%;
    left: 0;
    z-index: -1;
  }
  .site-setting .after {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  .site-setting .heading {
    padding-bottom: 30px;
  }
  .site-setting p {
    color: #000000a1;
    font-size: 17px;
    font-weight: 300;
    line-height: 24px;
  }
  /* Site Setting Css End Here */
  @media(max-width:768px){
    .site-setting h2 {
      font-size: 32px;
      line-height: normal;
  }
  }