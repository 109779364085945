/* Contact Css Start Here */
.contact_sec {
    padding: 40px 0px;
  }
  .contact_sec .heading {
    text-align: center;
    padding-bottom: 40px;
  }
  .contact_sec form {
    margin: 0px 50px;
  }
  .contact_sec form label {
    color: #2a263d;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .contact_sec form .form-group {
    margin-bottom: 15px;
  }
  .contact_sec form .form-control {
    border-radius: 50px;
    padding: 13px 30px;
    /* text-transform: lowercase; */
  }
  .contact_sec form textarea {
    height: 150px;
    border-radius: 15px !important;
    resize: none;
  }
  .contact_sec button.btn {
    background: #c23127;
    width: 100%;
    color: #fff;
    padding: 13px 10px;
    border-radius: 50px;
  }
  .contact_sec .address {
    text-align: center;
    margin-top: 20px;
  }
  .contact_sec .address .icon {
    background: #c23127;
    color: #fff;
    width: 60px;
    height: 60px;
    font-size: 30px;
    border-radius: 50%;
    line-height: 60px;
    margin: 0px auto 15px;
  }
  .contact_sec .address .txt h3 {
    font-size: 34px;
    color: #c23127;
    /* font-family: "oriental"; */
    line-height: 44px;
    padding-bottom: 15px;
    font-family: "Montserrat";
  }
  .contact_sec .address .txt .button-group a {
    color: #c23127;
    text-decoration: none;
    font-weight: 600;
  }
  .contact_sec .address .txt p {
    margin-bottom: 10px;
  }
  .contact_sec .address .txt p.colrd {
    color: #c23127;
  }
  /* Contact Css End Here */
  @media(max-width:768px){
    .contact_sec h2 {
      font-size: 32px;
      line-height: normal;
  }
  .contact_sec .address .txt h3 {
    font-size: 24px;
    line-height: 34px;
}
  }
  @media(max-width:576px){
    .contact_sec form {
      margin: 0px 10px;
  }

  }

  