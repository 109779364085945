/* Login Css Start Here */
h2{
    font-size: 56px;
    font-weight: 600;
    line-height: 66px;
    font-family: "Montserrat";
}
/* .newsletter-sec {
	display: none;
} */
/* footer {
	display: none;
} */
.login .before {
	position: absolute;
	top: -70px;
	filter: grayscale(1);
	z-index: -1;
	left: -50px;
}
.login {
	padding: 100px 0 150px;
	position: relative;
}
.login .auth-box {
	background: #f4f4f4;
	padding: 30px 60px;
	margin: 0px 50px;
}
.login .auth-box .heading {
	text-align: center;
	padding-bottom: 15px;
}
.login .auth-box .heading h2 {
	color: #c23127;
}
.login .auth-box h4 {
	font-size: 23px;
	font-family: "Montserrat";
	color: #000000;
	font-weight: 600;
}
.login .auth-box h4 span.bld {
	font-weight: 800;
	text-shadow: 0 0 black;
}
.login .auth-box p {
	font-weight: 400;
}
.login .auth-box p a {
	font-weight: 600;
	color: #c23127;
	margin-left: 4px;
}
.login .auth-box .form-group {
	margin-bottom: 15px;
}
.login .auth-box .form-group label {
	font-size: 15px;
	color: #2a263d;
	margin-bottom: 5px;
    font-family: "SF UI Display", sans-serif;
}
.login .auth-box button.btn span {
    font-family: "SF UI Display", sans-serif;
}
.login .auth-box .form-group .form-control {
	border: 1px solid #707070cf;
	border-radius: 50px;
	background: transparent;
	padding: 12px 20px;
}
.login .auth-box .remember-dv {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.login .auth-box .remember-dv .form-group {
	margin: 0;
}
.login .auth-box .remember-dv .form-group input {
    border-radius: 0;
    border: 1px solid #636060;
    background-color: #fff;
    box-shadow: none;
    padding: 9px 9px;
    margin-left: 0px;
}

.login .auth-box .remember-dv .form-check-input:checked[type="checkbox"] {
	background-image: url("../images/tick.png");
	background-size: 13px;
	background-position: center;
}
.login .auth-box .remember-dv .form-check-input:checked[type="checkbox"] {
	background-image: url("../images/tick.png");
}
.login .auth-box .remember-dv .form-group label {
    margin: 0 0 0 10px;
    font-size: 15px;
    color: #636060;
    font-family: "Poppins";
    padding-left: 25px;
}
.login .auth-box .remember-dv .link a {
	color: #0e3a20;
	font-size: 15px;
	text-decoration: none;
	font-family: "Poppins";
	font-weight: 500;
}
.login .auth-box button.btn {
	background: #c23127;
	border-radius: 50px;
	color: #fff;
	margin-top: 20px;
	padding: 11px 20px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-transform: uppercase;
	transition: 0.6s;
}
.login .auth-box button.btn:hover {
	background: transparent;
	color: #c23127;
	box-shadow: 0px 0px 0px 1px #c23127;
}
.login .auth-box button.btn img {
	filter: brightness(0) invert(1);
	transition: 0.6s;
}
.login .auth-box button.btn:hover img {
	filter: unset;
}
.login .after {
	position: absolute;
	bottom: 0px;
	right: 0;
	z-index: -1;
}
@media (max-width: 1350px) {
	.login .auth-box .heading h2 {
		font-size: 35px;
		line-height: 45px;
	}
	.login .auth-box {
		margin: 0px 20px;
	}
}
@media(max-width:768px){
	.login .auth-box {
		margin: 0px;
		padding: 30px 50px;
	}
}
@media(max-width:576px){
.login .auth-box {
    margin: 0px;
    padding: 30px;
}
.login .auth-box .remember-dv {
    display: block;
}
.login .auth-box .remember-dv .form-group {
    margin: 0 0px 20px 0px;
}
}

@media(max-width:290px){
	.login .auth-box h4 {
		font-size: 18px;
	}
}
/* Login Css End Here */