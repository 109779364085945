/* Shop Css Start Here */
.shop_sec .before {
	filter: grayscale(1);
	position: absolute;
	top: 17%;
	z-index: -1;
	left: 0;
}
.shop_sec {
	padding: 40px 0px;
	position: relative;
}
.shop_sec .heading {
	text-align: center;
	padding-bottom: 50px;
}
.shop_sec .sidebar {
	background: #c23127;
	padding: 30px 20px;
	color: #fff;
}
.shop_sec .sidebar .heading {
	text-align: left;
	padding: 0 0 5px;
	border-bottom: 1px solid #fff;
	margin-bottom: 15px;
}
.shop_sec .sidebar .heading h3 {
	/* font-family: "oriental"; */
	font-size: 30px;
	font-family: "Montserrat";
}
.shop_sec .sidebar .search form {
	display: flex;
}
.shop_sec .sidebar .search form .form-control {
	border-radius: 0;
	background: transparent;
	outline: none;
	box-shadow: none;
	border: 0;
	border-bottom: 1px solid #fff;
	padding: 7px 0 7px 5px;
	color: #fff;
}
.shop_sec .sidebar .search form .searchBtn {
	border: 0;
	background: transparent;
	border-bottom: 1px solid #fff;
	color: #ffffff7a;
}
.shop_sec .sidebar .search form .form-control::placeholder {
	color: #ffffff7a;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 15px;
}
.shop_sec .sidebar .filter .box {
	padding: 20px 0px 0;
}
.shop_sec .sidebar .filter .box .title {
	padding-bottom: 10px;
}
.shop_sec .sidebar .filter .box .title h4 {
	font-size: 18px;
}
.shop_sec .sidebar .filter .box ul.list {
	padding: 0;
	margin: 0;
	list-style: none;
}
.shop_sec .sidebar .filter .box ul.list li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid#ebebeb59;
	padding: 0px 10px 10px;
	margin-bottom: 15px;
}
.shop_sec .sidebar .filter .box ul.list li label {
	font-size: 14px;
	font-weight: 400;
}
.shop_sec .sidebar .filter .box ul.list li .form-check-input[type="checkbox"] {
	border-radius: 0;
	border: 0;
}
.shop_sec
	.sidebar
	.filter
	.box
	ul.list
	li
	.form-check-input:checked[type="checkbox"] {
	border-color: #fff !important;
	background-color: #fff !important;
	background-repeat: no-repeat;
	background-size: 10px;
	box-shadow: none;
}
.shop_sec .sidebar .filter .box .load-more a {
	color: #fff;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 400;
}
.shop_sec .sidebar .filter .box ul.list li:last-child {
	border: 0;
}
.shop_sec .sidebar .price-range {
	padding: 35px 0 35px;
}
.shop_sec .sidebar .price-range .input-group {
	flex-wrap: unset;
}
.shop_sec .sidebar .price-range .input-group .seprator {
	padding: 0px 10px;
}
.shop_sec .sidebar .price-range .input-group label {
	font-size: 13px;
	font-weight: 300;
}
.shop_sec .sidebar .price-range .input-group .form-control {
	border: 0;
	border-radius: 30px;
	color: #000;
	font-weight: 300;
	font-size: 14px;
	height: 40px;
}
.shop_sec .sidebar .zone .accordion-item {
	background: transparent;
	border: 0;
	border-radius: 0;
}
.shop_sec .sidebar .zone .accordion-item button {
	border-radius: 0;
	padding: 0;
	height: 40px;
	background: transparent;
	box-shadow: none;
	outline: none;
	color: #fff;
	font-family: "SF UI Display";
	font-weight: 500;
}
.shop_sec .sidebar .zone .accordion-item button::after {
	filter: brightness(0) invert(1);
}
.shop_sec .product-box .pro-detail h4 {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 0;
}
.paginatio-count {
	text-align: center;
	margin-top: 40px;
}
.paginatio-count ul {
	padding: 0;
	list-style: none;
}
.paginatio-count ul li.pagi {
	display: inline-block;
	border: 2px solid #b6b6b6;
	width: 30px;
	height: 28px;
	text-align: center;
	border-radius: 5px;
	margin: 0px 2px;
}
.paginatio-count ul li.pagi a {
	text-decoration: none;
	color: #6e6e6e;
}
.paginatio-count ul li.pagi.active {
	border-color: #c23127;
}
.paginatio-count ul li.pagi.active a {
	color: #c23127;
}
.paginatio-count ul li.arrow {
	background: #c23127;
	border-color: #c23127;
}
.paginatio-count ul li.arrow a {
	color: #fff;
}
.paginatio-count p.total-pages {
	font-size: 12px;
	color: #666666ba;
}
.kigibirds_sec .shop_sec .heading h2 {
    font-size: 56px;
    font-weight: 600;
    line-height: 66px;
    font-family: "Montserrat";
}
.product-box .pro-detail  a {
    font-family: "SF UI Display", sans-serif;
}
.shop_sec .product-box .pro-img figure {
    margin-bottom: 0;
}
/* Shop Css End Here */

/* Plant Mixes Css Start Here */
.similar {
	padding: 40px 0px;
	position: relative;
}
.similar .before {
	position: absolute;
	z-index: -1;
	left: -20%;
	top: 25%;
	filter: grayscale(1);
}
.similar .heading {
	text-align: center;
	padding-bottom: 30px;
}
.similar .heading h2 {
	color: #c23127;
	font-size: 40px;
}
.similar .heading p {
	color: #000000a8;
	max-width: 75%;
	margin: 0px auto 30px;
}
.plant-mixes .pro-img {
	border: 0;
	background: #f4f4f4;
	padding: 0 55px;
	border-radius: 20px;
}
section.plant-mixes .pro-img {
	height: 350px;
	border: 0;
}
.similar .pro-img {
	height: 260px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.similar .pro-img img {
	width: 100%;
}
.similar .product-box .pro-detail h4 {
	font-size: 16px;
}
.similar .after {
	position: absolute;
	z-index: -1;
	bottom: 25%;
	right: 0;
	width: 35%;
}
.shop_sec .after {
	position: absolute;
	bottom: 20%;
	right: 0;
	z-index: -1;
}
/* Plant Mixes Css End Here */

@media (max-width: 1024px) {
	.shop_sec .heading h2 {
		font-size: 40px;
		line-height: normal;
	}
	.shop_sec .sidebar .heading h3 {
		font-size: 26px;
	}
}
@media(max-width:768px){
	.similar .heading h2 {
		font-size: 32px;
		line-height: normal;
	}
	.shop_sec .sidebar {
		margin-bottom: 30px;
	}
}
@media(max-width:576px){
	.shop_sec .heading h2 {
		font-size: 26px;
		line-height: normal;
	}
}