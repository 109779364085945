/* Blogs Css Start Here */
.blogs {
    position: relative;
    padding: 60px 0px;
  }
  .blogs .before {
    position: absolute;
    z-index: -1;
    top: 30%;
    left: 0;
  }
  .blogs .blog-box .img-box figure {
    height: 210px;
    width: 100%;
    overflow: hidden;
  }
  .blogs .blog-box .img-box img {
    height: 210px;
    width: 100%;
    object-fit: cover;
  }
  .blogs .blog-box h3.title {
    font-size: 28px;
    color: #000000;
    line-height: 30px;
    margin-top: 10px;
  }
  .blogs .blog-box p {
    font-weight: 300;
    line-height: 21px;
    font-size: 14px;
    margin-top: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .blogs .blog-box .button-group a.btn {
    background: #c23127;
    color: #fff;
    text-transform: uppercase;
    padding: 6px 20px;
    border-radius: 50px;
    transition: 0.6s;
  }
  .blogs .blog-box .button-group a.btn img {
    filter: brightness(0) invert(1);
    margin-left: 15px;
    transition: 0.6s;
  }
  .blogs .blog-box .button-group a.btn:hover {
    background: transparent;
    box-shadow: 0px 0px 0px 1px #c23127;
    color: #c23127;
  }
  .blogs .blog-box .button-group a.btn:hover img {
    filter: unset;
  }
  .blogs .after {
    position: absolute;
    bottom: 20%;
    right: 0;
    z-index: -1;
  }
  /* Blogs Css End Here */